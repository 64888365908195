import { IApplyApi } from "../../shared/apiInterfaces";
import {
  DeiGoals,
  IApplicantProfile,
  IJobPublicInfo,
  IUnlockRequest,
} from "../../shared/apiModels";
import { getLogger } from "../loggingService";
import { ApiClientBase } from "./apiClientBase";

export class ApplyApiClient extends ApiClientBase implements IApplyApi {
  constructor(apiUrl: string) {
    super(apiUrl, getLogger("PublicApiClient"), false);
  }

  async getJobPublicInfo(siteUrl: string, jobId: string): Promise<IJobPublicInfo> {
    const result = await this.get(`/api/apply?siteUrl=${siteUrl}&jobId=${jobId}`);
    return await result.json();
  }

  async getDeiInfo(siteUrl: string): Promise<DeiGoals> {
    const result = await this.get(`/api/apply/dei?siteUrl=${siteUrl}`);
    return await result.json();
  }

  async postProfile(model: IApplicantProfile): Promise<{ id: string }> {
    const result = await this.post("/api/apply", model);
    return await result.json();
  }

  async postUnlock(req: IUnlockRequest): Promise<{status: string}> {
    const result = await this.post(`/api/apply/unlock`, req);
    return await result.json();
  }
}

