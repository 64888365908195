import { IApplicationApi, IApplyApi, ICompanyApi, IJobApi, IMiscApi, IRegisterApi, IUserApi } from "../../shared/apiInterfaces";
import { ApplicationApiClient } from "./applicationApiClient";
import { JobApiClient } from "./jobApiClient";
import { MiscApiClient } from "./miscApiClient";
import { ApplyApiClient } from "./applyApiClient";
import { UserApi } from "./userApiClient";
import { RegisterApiClient } from "./registerApiClient";
import { CompanyApiClient } from "./companyApiClient";

export class ApiClients {
  applications: IApplicationApi;
  apply: IApplyApi;
  company: ICompanyApi;
  jobs: IJobApi;
  misc: IMiscApi;
  registration: IRegisterApi;
  user: IUserApi;

  constructor(apiUrl: string){
    this.applications = new ApplicationApiClient(apiUrl);
    this.apply = new ApplyApiClient(apiUrl);
    this.company = new CompanyApiClient(apiUrl);
    this.jobs = new JobApiClient(apiUrl);
    this.misc = new MiscApiClient(apiUrl);
    this.registration = new RegisterApiClient(apiUrl);
    this.user = new UserApi(apiUrl);
  }
}