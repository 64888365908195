import { Grid } from "@material-ui/core";
import React from "react";
import { CompanySettingsComponent } from "./companySettings";
import { Users } from "./users";

export function Settings() {

  return (
    <Grid container justify={"center"}>
      <Users />
      <CompanySettingsComponent />
    </Grid>
  );
}