import { ApiClientBase } from "./apiClientBase";
import { getLogger } from "../loggingService";
import { IMiscApi } from "../../shared/apiInterfaces";

export class MiscApiClient extends ApiClientBase implements IMiscApi {

  constructor(apiUrl: string) {
    super(apiUrl, getLogger("ApplicationApiClient"), true)
  }

  async getSso(): Promise<{ ssoUrl: string }> {
    const result = await this.get(`/api/sso`);
    const data = await result.json();
    return data;
  }
}
