import { Grid } from "@material-ui/core";
import queryString from 'query-string';
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import { getLogger } from "../../services/loggingService";
import { Alert } from "@material-ui/lab";
import { AppAlert } from "../../models/appAlert";

const logger = getLogger("ApplicantProfileUnlock");

export function ApplicantProfileUnlock() {

  const context = useContext(AppContext);
  const [alert, setAlert] = useState<AppAlert>(new AppAlert("Connecting...", "info"));

  useEffect(() => {
    const parsed = queryString.parse(window.location.search);
    const expected = ["action", "applicationId", "companyId", "jobId", "timestamp", "signature"];
    for (const param of expected) {
      if (!parsed[param]) {
        setAlert(new AppAlert("Unlock link format is invalid, please check your email.", "error"))
        return;
      }
    }

    context.api.apply.postUnlock({
      signedLink: window.location.toString()
    }).then(result => {
      setAlert(new AppAlert(result.status, "success"))
    }).catch(error => {
      logger.error(error.message);
      setAlert(new AppAlert(error.message, "error"))
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Grid item container xs={12} md={8} spacing={3} className="titan-form">
      <Grid item xs={12}>
        <Alert severity={alert.type}>{alert.message}</Alert>
      </Grid>
    </Grid>
  )
}