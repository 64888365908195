// For some reason this import needs to be here even though its not used
import {Auth} from 'aws-amplify';
import {getLogger, Logger} from './loggingService';

interface ICognitoConfig {
  userPoolId: string;
  clientId: string;
}
export default class ConfigurationService {
  private logger: Logger;

  // These properties are assigned from config.json
  environment: string;
  region: string;
  apiUrl: string;
  cognito: ICognitoConfig;
  buildTime: string;
  version: string;

  constructor() {
    this.logger = getLogger('ConfigurationService');
  }

  public async load() {
    const response = await fetch('/config.json');
    const cfg = await response.json();
    Object.assign(this, cfg);
    this.logger.debug('Config Loaded');
    this.configureAmplify();
  }

  get loginUrl(): string {
    return window.location.origin;
  }

  isLocal(): boolean {
    return this.environment === 'local';
  }

  isDebugVisible(): boolean {
    return this.isLocal() || window.location.search.includes('debug');
  }

  private configureAmplify(): void {
    const options = {
      Analytics: {
        disabled: true
      },
      Auth: {
        region: this.region,
        userPoolId: this.cognito.userPoolId,
        userPoolWebClientId: this.cognito.clientId,
        mandatorySignIn: false
      }
    };

    Auth.configure(options.Auth);
  }
}
