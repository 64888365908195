/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, IconButton, Dialog, DialogTitle, DialogContent, Box, Link, Select, MenuItem, InputLabel, TextField, Tooltip } from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import LockIcon from '@material-ui/icons/Lock';
import { ApplicantProfileLockStatus, IAppId, IApplicantProfile, ISendEmail, IUser } from "../../shared/apiModels";
import { LockOpen } from "@material-ui/icons";
import BlockIcon from '@material-ui/icons/Block';
import { useHistory } from "react-router";
import ShareIcon from '@material-ui/icons/Share';
import CloseIcon from '@material-ui/icons/Close';
import { useUsers } from "../../hooks/useUsers";
import { Button } from "aws-amplify-react";
import PrintIcon from '@material-ui/icons/Print';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import MyDocument from "./pdfDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import { useApplicant } from '../../hooks/useApplicant';
import { useJobPublicInfo } from '../../hooks/useJobPublicInfo';


export function LockStatusIcon(props: { status: ApplicantProfileLockStatus, fontSize?: string }) {
  switch (props.status) {
    case "locked":
      return <LockIcon style={{ color: "#B81D13", fontSize: props.fontSize }} />
    case "pending":
      return <LockIcon style={{ color: "#EFB700", fontSize: props.fontSize }} />
    case "unlocked":
      return <LockOpen style={{ color: "#008450", fontSize: props.fontSize }} />
    case "declined":
      return <BlockIcon style={{ color: "#B81D13", fontSize: props.fontSize }} />;
  }
}

interface IProps {
  appId: IAppId;
  lockStatus: ApplicantProfileLockStatus;
  applicant: IApplicantProfile;
}
export function ProfileIcons(props: IProps) {

  const [status, setStatus] = useState(props.lockStatus || "locked");
  const [loading, setLoading] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const [openPrint, setOpenPrint] = useState(false);
  const [refresh, setRefresh] = useState(new Date());
  const users = useUsers(refresh);
  const ctx = useContext(AppContext);
  const history = useHistory();
  const [emailRecipient, setRecipient] = useState("");
  const [emailBody, setEmailBody] = useState("");
  let sendingEmail = false;

  /*useEffect( () => {
    ctx.api.company.getSettings().then(async x =>{
      //console.log(x.shareProfileMessage);
      let emailTemplate = x.shareProfileMessage.replace("#LINK",window.location.href);
      const jobDetails = await ctx.api.jobs.getJob(props.appId.jobId);
      emailTemplate = emailTemplate.replace("#JobTitle", jobDetails.title);
      // ctx.claims.username
      const currentUser = users.result.find(x => {
        return x.id === ctx.claims.user_id
      });
      //console.log(currentUser);
      emailTemplate = emailTemplate.replace("#SenderName", currentUser.first_name + " " + currentUser.last_name);
      console.log(ctx.claims.email);
      setEmailBody(emailTemplate);
    });
  }, [ctx, users]);*/



  const handleClick = async () => {
    try {
      console.log("click - " + status);
      setLoading(true);

      if (status === "unlocked") {
        history.push(`/sso/admin/jobs/${props.appId.jobId}#/app/${props.appId.applicationId}`);
      } else if (status === "locked") {
        await ctx.api.applications.unlock(props.appId);
        setStatus("pending");
        ctx.setAlert("Unlock request sent", "success");
      } else {
        // TODO should we offer a resend?
      }
    } catch (error) {
      ctx.setAlert(error.message, "error");
    } finally {
      setLoading(false);
    }
  }

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      backgroundColor: '#E4E4E4'
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1
    }
  });

  const theDocument = () => (<Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
      <View style={styles.section}>
        <Text>Section #2</Text>
      </View>
    </Page>
  </Document>);

  const shareProfile = async () => {
    //console.log(x.shareProfileMessage);
    setLoading(true);
    const settings = await ctx.api.company.getSettings();
    let emailTemplate = settings.shareProfileMessage?.replace("#LINK", window.location.href);
    const jobDetails = await ctx.api.jobs.getJob(props.appId.jobId);
    emailTemplate = emailTemplate.replace("#JobTitle", jobDetails.title);
    // ctx.claims.username
    const currentUser = users.result.find(x => {
      return x.id === ctx.claims.user_id
    });
    //console.log(currentUser);
    emailTemplate = emailTemplate.replace("#SenderName", currentUser.first_name + " " + currentUser.last_name);
    //console.log(ctx.claims.site_url);
    
    setRecipient(users.result[0].email)
    setEmailBody(emailTemplate);
    setOpenShare(true);
    setLoading(false);
  }

  const printProfile = async () => {
    console.log('printing');
    setOpenPrint(true);


    //ReactDOM.render(theDocument(), document.getElementById('root'));
  }

  const sendEmail = async () => {
    sendingEmail = true;
    try {
      const req: ISendEmail = {
        recipient: emailRecipient,
        sender: ctx.claims.email,
        subject: 'Check this profile',
        body: emailBody,
        siteUrl: ctx.claims.site_url,
        applicationId: props.appId.applicationId,
        companyId: ctx.claims.company_id,
        jobId: props.appId.jobId
      };
      await ctx.api.applications.sendEmail(req);
      ctx.setAlert("Email sent", "success");
    } catch (error) {
      ctx.setAlert(error.message, "error");
    } finally {
      setOpenShare(false);
      sendingEmail = false;
    }


  }

  const User = (user: IUser) => {
    return (
      <option value={user.email}>
        {user.first_name + ' ' + user.last_name}
      </option >
    )
  }
  // @ts-ignore
  const handleChange = async (event) => {
    setRecipient(event.target.value);
  };

  // @ts-ignore
  const handleBodyChange = (event) => {
    console.log(event.target.value);
    setEmailBody(event.target.value);
  }

  const fileName = props.applicant.firstName.toString() + ' ' + props.applicant.lastName.toString() + ".pdf";


  return (
    <>
      <Grid item xs={5} sm={4} md={3} style={{ margin: "auto", textAlign: "right", padding: "10px" }}>
        <Tooltip title="" aria-label="add">
          <IconButton className="unlockIcon" onClick={() => handleClick()} disabled={loading}>
            <LockStatusIcon status={status} fontSize={"1.4em"} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Share Profile" aria-label="add">
          <IconButton className="shareIcon" disabled={loading} onClick={() => shareProfile()}>
            <ShareIcon>Share</ShareIcon>
          </IconButton>
        </Tooltip>
        <PDFDownloadLink document={<MyDocument userData={ props.applicant } />} fileName= {fileName}>
          {({ loading, error }) => (loading ? 'loading document' :
            <>
              <Tooltip title="Download Profile" aria-label="add">
                <IconButton className="CloudDownloadIcon" disabled={loading}>
                  <CloudDownloadIcon>Download</CloudDownloadIcon>
                </IconButton>
              </Tooltip>
            </>
          )}
        </PDFDownloadLink>
      </Grid>
      <Dialog onClose={() => { setOpenShare(false); }} aria-labelledby="simple-dialog-title" open={openShare} maxWidth="sm" fullWidth={true}>
        <DialogTitle id="simple-dialog-title">
          <IconButton onClick={() => setOpenShare(false)} className='popup-close-button'>
            <CloseIcon />
          </IconButton>
          Share Profile
        </DialogTitle>
        <DialogContent>

          <Grid>
            <Box m={1}>
              <br />
              <InputLabel htmlFor="recipient-input">Recipient</InputLabel>
              <Select
                inputProps={{ name: 'recipient-input', id: 'recipient-input' }}
                native
                label="Recipient"
                onChange={handleChange}
                fullWidth={true}
                value={emailRecipient}
              >
                {users.result.map(x => (<User {...x} key={x.id} />))}
              </Select>
              <br />
              <TextField
                id="filled-multiline-static"
                label="Message"
                multiline
                rows={10}
                variant="filled"
                fullWidth={true}
                onChange={handleBodyChange}
                value={emailBody}
              />
            </Box>
            <div style={{ textAlign: "center" }}>
              <Button onClick={() => sendEmail()} >
                Send Email
              </Button>
            </div>
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog onClose={() => { setOpenPrint(false); }} aria-labelledby="simple-dialog-title" open={openPrint} fullWidth={true}>
        <DialogTitle id="simple-dialog-title">
          <IconButton onClick={() => setOpenPrint(false)} className='popup-close-button'>
            <CloseIcon />
          </IconButton>
          Print Profile
        </DialogTitle>
        <DialogContent>
          <div id="profilePDF">
            <PDFDownloadLink document={theDocument()} fileName="somename.pdf">
              {({ loading, error }) => (loading ? 'loading document' :
                <>
                  <IconButton className="printIcon" disabled={loading} onClick={() => printProfile()}>
                    <PrintIcon>Print</PrintIcon>
                  </IconButton>
                </>
              )}
            </PDFDownloadLink>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );


}