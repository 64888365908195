import { Grid } from "@material-ui/core";
import React from "react";
import "./footer.css";

export default class Footer extends React.Component {
  render() {
    return (
      <Grid container item xs={12} className="footer">
        <Grid item xs={12} md={6}>
          Titan ATS, Applicant Tracking System (ATS), Recruiting
        </Grid>
        <Grid item xs={12} md={6}>
          <a href="mailto:info@titanats.com" id="emaillink">
            info@titanats.com
          </a>, Phone: (972) 379-8212
        </Grid>
      </Grid>
    );
  }
}
