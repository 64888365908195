import React, { useContext } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import AppContext from "../../contexts/appContext";
import { ConfirmSignIn, ForgotPassword, RequireNewPassword, SignIn, VerifyContact, withAuthenticator } from 'aws-amplify-react';

const MyTheme = {
  button: { backgroundColor: "#68c6b4" },
  a: { color: "#68c6b4" },
}


interface IProps extends RouteProps {
  admin?: boolean;
}

function ProtectedRoute(props: IProps) {
  const ctx = useContext(AppContext);
  
  if (props.admin && !["AccountOwner", "Admin"].includes(ctx.claims.admin_level)) {
    return <Redirect to="/" />
  }

  return <Route path={props.path} component={props.component} />;
}

export default withAuthenticator(ProtectedRoute, false, [
  <SignIn />,
  <ConfirmSignIn/>,
  <VerifyContact/>,
  <ForgotPassword/>,
  <RequireNewPassword />
], null, MyTheme);