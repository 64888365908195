import React, { useContext } from "react";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import InboxIcon from "@material-ui/icons/MoveToInbox";
import WorkIcon from '@material-ui/icons/Work';
import TransferWithinAStationIcon from "@material-ui/icons/TransferWithinAStation";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory } from 'react-router-dom';
import AppContext from '../../contexts/appContext';
import AmplifyService from '../../services/amplifyService';

const ignoreRoutes = [
  "/apply",
  "/unlock",
  "/register"
]
function ignorePath(path: string): boolean {
  for (const route of ignoreRoutes) {
    if(path.startsWith(route)){
      return true;
    }
  }
  return false;
}

export function TitanWideMenu() {

  const ctx = useContext(AppContext);
  const history = useHistory();

  const [value, setValue] = React.useState(window.location.pathname);

  history.listen(location => {
    console.log("history", location.pathname)
    setValue(location.pathname);
  });

  const onChange = (event: any, newValue: string) => {
    if (newValue === "login") {
      AmplifyService.federatedLogin()
    } else if (newValue === "logout") {
      ctx.logout()
    } else {
      history.push(newValue);
    }
  }

  if (!ctx.claims || ignorePath(value)) {
    // User not logged in, or on the apply route
    return null;
  }

  const icons = [];

  icons.push(<BottomNavigationAction label="Jobs" value="/jobs" key="jobs" icon={<WorkIcon />} />);
  icons.push(<BottomNavigationAction label="Applicants" value="/" key="applicants" icon={<InboxIcon />} />);
  icons.push(<BottomNavigationAction label="ATS" value="/sso" key="sso" icon={<TransferWithinAStationIcon />} />);

  if (["AccountOwner", "Admin"].includes(ctx.claims.admin_level)) {
    icons.push(<BottomNavigationAction label="Settings" value="/settings" key="users" icon={<SettingsIcon />} />);
  }

  icons.push(<BottomNavigationAction label="LOGOUT" value="logout" key="logout" icon={<ExitToAppIcon />} />);

  return (
    <BottomNavigation
      value={value}
      onChange={onChange}
      showLabels
    >
      {icons}
    </BottomNavigation>
  );
}