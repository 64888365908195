import { Button, IconButton, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import {
  Grid,
  Typography,
} from "@material-ui/core";
import { getIn, useFormik } from "formik";
import React, { useContext, useState } from "react";
import { IUser } from "../../shared/apiModels";
import AppContext from "../../contexts/appContext";
import { useUsers } from "../../hooks/useUsers";
import { getLogger } from "../../services/loggingService";
import { TitanSelectField, TitanTextField } from "../base/titanFormInput";
import { TitanLoading } from "../base/titanLoading";
import DeleteIcon from '@material-ui/icons/Delete';
import { UserSchema } from "../../shared/apiValidation";

const logger = getLogger("Users");

export function Users(){

  const [readOnly, setReadOnly] = useState(false);
  const [refresh, setRefresh] = useState(new Date());
  const users = useUsers(refresh);

  const ctx = useContext(AppContext);

  const { handleBlur, setFieldValue, handleSubmit, values, errors, touched, resetForm } = useFormik<IUser>({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      admin_level: "Basic",
    },
    validationSchema: UserSchema,
    onSubmit: async (values) => {
      try {
        setReadOnly(true);
        logger.debug(values)
        await ctx.api.user.createUser(values);
        setRefresh(new Date())
        resetForm();
        ctx.setAlert("User created", "success");
      } catch (e) {
        logger.error(e.message);
        ctx.setAlert(e.message, "error");
      } finally {
        setReadOnly(false);
      }
    },
  });

  /**
 * Returns error text if touched. Works with nested objects.
 */
  const getError = (name: string): string => {
    return getIn(touched, name) && getIn(errors, name)
      ? getIn(errors, name)
      : "";
  };

  const deleteUser = async (userId: string) => {
    if (ctx.claims.user_id === userId) {
      return;
    }
    try {
      setReadOnly(true)
      await ctx.api.user.deleteUser(userId);
      setRefresh(new Date());
    } catch (e) {
      logger.error(e.message);
      ctx.setAlert(e.message, "error");
    } finally {
      setReadOnly(false);
    }
  }

  const formProps = {
    getValue: (name: string) => getIn(values, name),
    getError,
    handleBlur,
    setFieldValue,
    readOnly
  }


  const User = (user: IUser) => {
    const currentUser = ctx.claims.user_id === user.id;
    return <ListItem key={user.id}>
      <ListItemAvatar>
        <IconButton onClick={() => deleteUser(user.id)} disabled={currentUser} >
          <DeleteIcon />
        </IconButton>
      </ListItemAvatar>

      <ListItemText primary={`${user.first_name} ${user.last_name} - ${user.admin_level}`} secondary={`${user.email}, ${user.phone}`} />
    </ListItem>
  }

  const renderContent = () => {

    if (users.loading) {
      return (<TitanLoading />);
    }

    return <React.Fragment>
      <Grid item xs={12} md={6}>
        <List>
          {users.result.map(x => (<User {...x} key={x.id} />))}
        </List>
      </Grid>
      <Grid item xs={12} md={6}>
        <Grid container spacing={1}>
          <TitanTextField
            name="first_name"
            label="First Name"
            xs={12}
            sm={6}
            {...formProps}
          />
          <TitanTextField
            name="last_name"
            label="Last Name"
            xs={12}
            sm={6}
            {...formProps}
          />
          <TitanTextField
            name="email"
            label="Email"
            xs={12}
            sm={6}
            {...formProps}
          />
          <TitanTextField
            name="phone"
            label="Phone"
            xs={12}
            sm={6}
            {...formProps}
          />
          <TitanSelectField
            name="admin_level"
            label="Admin Level"
            xs={12}
            sm={6}
            options={["Admin", "Basic"].map(x => ({ label: x, value: x }))}
            {...formProps}
          />
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              className="left-button"
              onClick={() => handleSubmit()}
              disabled={readOnly}
            >Add</Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          component="span"
          variant="h4"
          color="textPrimary"
        >
          Users</Typography>
      </Grid>
      {renderContent()}
    </Grid>
  );
}