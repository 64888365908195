import moment from 'moment';
import {useContext, useEffect, useState} from 'react';
import {IApplicantProfile, IAppId, Position} from '../shared/apiModels';
import AppContext from '../contexts/appContext';
import {getLogger} from '../services/loggingService';

export const defaultApplicantProfile = (siteUrl: string, companyId: string, jobId: string): IApplicantProfile => {
  return {
    siteUrl: siteUrl,
    companyId: companyId,
    jobId: jobId,
    jobTitle: '',
    applicationId: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    headline: '',
    problemsSolved: '',
    position1: new Position(),
    position2: new Position(),
    position3: new Position(),
    averageTenure: '',
    education: '',
    volunteer: '',
    skill1: {
      name: '',
      level: ''
    },
    skill2: {
      name: '',
      level: ''
    },
    skill3: {
      name: '',
      level: ''
    },
    skill4: {
      name: '',
      level: ''
    },
    additionalSkills: '',
    startAvailability: moment().add(14, 'days'),
    coverLetter: '',
    lockStatus: 'locked'
  };
};

export const useApplicant = (jobId?: string, applicationId?: string) => {
  const context = useContext(AppContext);
  const logger = getLogger('useApplicant');

  const [loading, setLoading] = useState(true);
  const [id, setId] = useState<IAppId>(null);
  const [result, setResult] = useState(null as IApplicantProfile);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      try {
        logger.debug('Fetching', {applicationId});
        const data = await context.api.applications.getApplication(jobId, applicationId);

        if (data) {
          setResult(data);
          setId({companyId: data.companyId, jobId: data.jobId, applicationId: data.applicationId});
        }
      } catch (error) {
        logger.error(error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    if (jobId && applicationId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationId]);

  return {
    error,
    loading,
    id,
    result
  };
};
