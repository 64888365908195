export const FormOptions = {
  disposition: [
    {
      label: "New",
      value: "New"
    },
    {
      label: "Screen",
      value: "Screen"
    },
    {
      label: "Phone Interview",
      value: "Phone Interview"
    },
    {
      label: "Not Hired - Not Qualified",
      value: "Not Hired - Not Qualified"
    },
    {
      label: "Not Hired - Failed Interview",
      value: "Not Hired - Failed Interview"
    },
    {
      label: "Not Hired - Do Not Use",
      value: "Not Hired - Do Not Use"
    },
  ],
  skill: [
    {
      label: "Beginner",
      value: "1"
    },
    {
      label: "Proficient",
      value: "5"
    },
    {
      label: "Expert",
      value: "10"
    }
  ],
  YesNo: [
    {
      label: "Yes",
      value: "yes"
    },
    {
      label: "No",
      value: "no"
    }
  ],
}