import React, { useEffect, useState } from "react";
import { FormControl, Grid, IconButton, InputLabel, Link, MenuItem, Select } from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import { TitanLoading } from "../base/titanLoading";
import { useApplicantList } from "../../hooks/useApplicantList";
import { useHistory, useParams } from "react-router-dom";
import { IDropDownOptions } from "../../models/dropDownOptions";
import _ from "lodash";
import { LockStatusIcon } from "./profileIcons";
import { IApplicantProfileList } from "../../shared/apiModels";
import MaterialTable from "material-table";
interface IParams {
  jobId?: string;
}

export function ApplicantProfileList() {
  const history = useHistory();

  const params: IParams = useParams();

  const [jobs, setJobs] = useState([] as IDropDownOptions[]);
  const [jobId, setJobId] = useState(params.jobId || "");
  const [refresh, setRefresh] = useState(new Date().getTime());

  const applicants = useApplicantList(refresh);

  const [visibleApps, setVisibleApps] = useState<IApplicantProfileList[]>([])

  useEffect(() => {
    if (!applicants.loading) {
      const uniqueJobs = _.chain(applicants.result).map(x => ({ key: x.jobId, value: x.jobId, text: x.jobTitle })).uniqBy(x => x.value).orderBy(x => x.text).value();
      setJobs(uniqueJobs);

      const visible = jobId
        ? applicants.result.filter(x => x.jobId === jobId)
        : applicants.result;

      setVisibleApps(visible);
    }
  }, [applicants.loading, applicants.result, jobId])

  const renderIdCell = (app: IApplicantProfileList) => {
    return (
      <div>
        <IconButton onClick={() => history.push(`/profile?jobId=${app.jobId}&applicationId=${app.applicationId}`)}>
          <LockStatusIcon status={app.lockStatus} />
        </IconButton>
      </div>
    );
  };

  const renderHeadlineCell = (app: IApplicantProfileList) => {
    return (
      <div>
        <Link href={`/profile?jobId=${app.jobId}&applicationId=${app.applicationId}`} color="inherit">
        {app.headline}
        </Link>
      </div>
    );
  };


  if (applicants.loading) {
    return <TitanLoading />;
  }

  return (
    <Grid container item spacing={3}>
      <Grid
        item
        xs={8}
        sm={6}
        md={4}
      >
        <FormControl variant="outlined" fullWidth={true}>
          <InputLabel id={`job-label`}>Job</InputLabel>
          <Select
            labelId={`job-label`}
            onChange={(e) => setJobId(e.target.value as string)}
            label="Job"
            fullWidth={true}
            value={jobId}
          >
            <MenuItem value="" key={0}>
              <em>All</em>
            </MenuItem>
            {jobs.map((o, index) => (
              <MenuItem value={o.value} key={index + 1}>
                {o.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4} sm={6} md={8} className="top-toolbar">
        <IconButton
          onClick={() => setRefresh(new Date().getTime())}
          style={{ float: "right" }}
          disabled={applicants.loading}
        >
          <CachedIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12} className="table">
        <MaterialTable
          title=""
          columns={[
            {title: '', field: 'id', render: renderIdCell},
            {title: 'Job', field: 'jobTitle'},
            {title: 'Headline', field: 'headline', render: renderHeadlineCell},
            {title: 'Average Tenure', field: 'averageTenure'},
            {title: 'Applied', field: 'appliedAt', render: (x)=> (new Date(x.appliedAt).toLocaleString())},
            {title: 'Status', field: 'status'},
          ]}
          data={visibleApps}
          options={{
            pageSize: 10
          }}
        ></MaterialTable>
      </Grid>
    </Grid>
  );
};
