import { Button, Grid, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { getIn, useFormik } from "formik";
import React, { useState } from "react";
import AppContext, { IAppContext } from "../../contexts/appContext";
import { IRegister } from "../../shared/apiModels";
import { getLogger } from "../../services/loggingService";
import { TitanSelectField, TitanTextField } from "../base/titanFormInput";
import { UsStates } from "../data/usStates";
import { RegisterSchema } from "../../shared/apiValidation";

const logger = getLogger("Registration");

const field: any = {
  xs: 12,
  sm: 6,
};

const RegistrationForm = (props: { context: IAppContext; ref: any }) => {
  const [successMessage, setSuccessMessage] = useState("");

  const submit = async (values: IRegister): Promise<void> => {
    try {
      logger.debug("Registration Form", values);
      await props.context.api.registration.register(values);
      setSuccessMessage("Registration successful, please check your email for login instructions");
    } catch (error) {
      if (error.status === 422 && error.errors.site_url) {
        props.context.setAlert(
          "Site URL is invalid, please choose another",
          "error"
        );
      } else {
        props.context.setAlert(error.message || error.detail, "error");
      }
    }
  };

  const { handleBlur, setFieldValue, handleSubmit, values, errors, touched } = useFormik<IRegister>({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      company: "",
      siteUrl: "",
      city: "",
      state: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: submit,
  });

    /**
   * Returns error text if touched. Works with nested objects.
   */
  const getError = (name: string): string => {
    return getIn(touched, name) && getIn(errors, name)
      ? getIn(errors, name)
      : "";
  };

  if (successMessage) {
    return (
      <Grid container item xs={12} justify="center" alignContent="flex-start">
        <Grid item xs={3}>
          <Alert severity="success">
            <h4>Success</h4>
            <p>{successMessage}</p>
          </Alert>
        </Grid>
      </Grid>
    );
  }

  const formProps = {
    getError,
    getValue: (name: string) => getIn(values, name),
    handleBlur,
    setFieldValue,
  }

  return (
    <Grid
      item
      container
      xs={12}
      md={8}
      xl={6}
      spacing={3}
      className="titan-form"
    >
      <Grid item xs={12}>
        <Typography variant="h2" component="h2" gutterBottom>
          Register
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography component="h3" variant="h3" gutterBottom>
          Account Owner
        </Typography>
      </Grid>
      <TitanTextField
        name="firstName"
        label="First Name"
        {...formProps}
        {...field}
      />
      <TitanTextField
        name="lastName"
        label="Last Name"
        {...formProps}
        {...field}
      />
      <TitanTextField
        name="email"
        label="Email"
        {...formProps}
        {...field}
      />
      <TitanTextField
        name="phone"
        label="Phone"
        {...formProps}
        {...field}
      />

      <Grid item xs={12}>
        <Typography component="h3" variant="h3" gutterBottom>
          Company Information
        </Typography>
      </Grid>

      <TitanTextField
        name="company"
        label="Company"
        {...formProps}
        xs={12}
      />
      <TitanTextField
        name="siteUrl"
        label="Site URL"
        {...formProps}
        xs={12}
        sm={5}
      />

      <TitanTextField
        name="city"
        label="City"
        {...formProps}
        xs={12}
        sm={4} />

      <TitanSelectField
        name="state"
        label="State"
        {...formProps}
        options={UsStates}
        xs={12}
        sm={3}
      />
      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          className="submit-button"
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

const Registration = React.forwardRef((props: {}, ref: any) => (
  <AppContext.Consumer>
    {(context) => <RegistrationForm {...props} context={context} ref={ref} />}
  </AppContext.Consumer>
));
Registration.displayName = "Registration";
export default Registration;
