import {Box, Button, Dialog, DialogContent, DialogTitle, Grid, IconButton, Link, Typography} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, {useContext, useEffect, useState} from 'react';
import AppContext from '../../contexts/appContext';
import {DeiGoals} from '../../shared/apiModels';
import DeiProgress from './deiProgress';

interface IProps {
  siteUrl: string;
}
export function DeiInformation(this: any, props: IProps) {
  const [data, setData] = useState<DeiGoals>(null);
  const [open, setOpen] = useState(false);
  const [openReviews, setOpenReviews] = useState(false);

  const ctx = useContext(AppContext);

  useEffect(() => {
    if (props.siteUrl) {
      ctx.api.apply
        .getDeiInfo(props.siteUrl)
        .then((info) => setData(info))
        .catch((error) => ctx.setAlert(error.message, 'error'));
    }
  }, [ctx, props.siteUrl]);

  if (!data || !data.enabled) {
    return null;
  }

  return (
    <React.Fragment>
      <Button
        variant={'contained'}
        color="primary"
        onClick={() => setOpen(true)}
        style={{color: 'white', fontSize: 'small'}}
      >
        Check Out Our
        <br />
        Diversity!
      </Button>
      <Button
        variant={'contained'}
        color="primary"
        onClick={() => setOpenReviews(true)}
        style={{color: 'white', fontSize: 'small', margin: '0 0px 0px 15px'}}
      >
        Check Out Our Employee 
        <br />
        And Candidate Reviews
      </Button>
      <Dialog onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">
          <IconButton onClick={() => setOpen(false)} className='popup-close-button'>
            <CloseIcon />
        </IconButton>
          Company Diversity, Equity, and Inclusion Accountability
        </DialogTitle>
        <DialogContent>
        {data.missionStatement && (
            <div className='popup-subtitle'>
              {data.missionStatement}
            </div>
          )}
          <Grid container spacing={2}>

          {data.companySurveyResultsLink && (
              <Grid item xs={6}>
                <Box mb={'10px'}>
                  <Link target="_blank" rel="noopener noreferrer" href={data.companySurveyResultsLink}>
                    Company Diversity Make-Up
                  </Link>
                  <br />
                  Check out the diversity of our organization.
                </Box>
              </Grid>
            )}

            {data.executiveSurveyResultsLink && (
              <Grid item xs={6}>
                <Box mb={'10px'}>
                  <Link target="_blank" rel="noopener noreferrer" href={data.executiveSurveyResultsLink}>
                    Executive Diversity Make-Up
                  </Link>
                  <br />
                  Check out our Executive Team's diversity
                </Box>
              </Grid>
            )}

            {/*data.candidateSurveyResultsLink && (
              <Grid item xs={6}>
                <Box mb={'10px'}>
                  <Link target="_blank" rel="noopener noreferrer" href={data.candidateSurveyResultsLink}>
                    Learn What Candidates Have To Say
                  </Link>
                  <br />
                  This survey is sent to every candidate that interviews with the company.
                </Box>
              </Grid>
            )*/}

            {/*data.employeeSurveyResultsLink && (
              <Grid item xs={6}>
                <Box mb={'10px'}>
                  <Link target="_blank" rel="noopener noreferrer" href={data.employeeSurveyResultsLink}>
                    Learn What Employees Have To Say
                  </Link>
                  <br />
                  This survey is sent to every employee within the organization.
                </Box>
              </Grid>
            )*/}

            <Grid xs={12} item>
              {/* <Typography
                component="span"
                variant="h5"
                color="textPrimary">
                Goals
              </Typography> */}
              {/*data.goal1?.goalStatement && (
                <DeiProgress
                  goalStatement={data.goal1.goalStatement}
                  actionTaken={data.goal1.actionTaken}
                  percentComplete={data.goal1.percentComplete}
                />
              )*/}
              {/*data.goal2?.goalStatement && (
                <DeiProgress
                  goalStatement={data.goal2.goalStatement}
                  actionTaken={data.goal2.actionTaken}
                  percentComplete={data.goal2.percentComplete}
                />
              )*/}
              {/*data.goal3?.goalStatement && (
                <DeiProgress
                  goalStatement={data.goal3.goalStatement}
                  actionTaken={data.goal3.actionTaken}
                  percentComplete={data.goal3.percentComplete}
                />
              )*/}
              {/*data.goal4?.goalStatement && (
                <DeiProgress
                  goalStatement={data.goal4.goalStatement}
                  actionTaken={data.goal4.actionTaken}
                  percentComplete={data.goal4.percentComplete}
                />
              )*/}
              {/*data.goal5?.goalStatement && (
                <DeiProgress
                  goalStatement={data.goal5.goalStatement}
                  actionTaken={data.goal5.actionTaken}
                  percentComplete={data.goal5.percentComplete}
                />
              )*/}
            </Grid>
            
          </Grid>
        </DialogContent>
      </Dialog>
      <Dialog onClose={() => {setOpenReviews(false)}} aria-labelledby="simple-dialog-title" open={openReviews} >
        <DialogTitle id="simple-dialog-title">
          <IconButton onClick={() => setOpenReviews(false)} className='popup-close-button'>
            <CloseIcon />
        </IconButton>
          Employee and Candidate Reviews
        </DialogTitle>
        <DialogContent>
        <Grid container spacing={2}>

          {data.candidateSurveyResultsLink && (
              <Grid item xs={6}>
                <Box mb={'10px'}>
                  <Link target="_blank" rel="noopener noreferrer" href={data.candidateSurveyResultsLink}>
                    Learn What Candidates Have To Say
                  </Link>
                  <br />
                  This survey is sent to every candidate that interviews with the company.
                </Box>
              </Grid>
            )}

            {data.employeeSurveyResultsLink && (
              <Grid item xs={6}>
                <Box mb={'10px'}>
                  <Link target="_blank" rel="noopener noreferrer" href={data.employeeSurveyResultsLink}>
                    Learn What Employees Have To Say
                  </Link>
                  <br />
                  This survey is sent to every employee within the organization.
                </Box>
              </Grid>
            )}
            </Grid>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
