import {
  Box,
  ClickAwayListener,
  FormControl,
  Grid,
  GridSize,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInputProps,
  Paper,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';

import Autocomplete from '@material-ui/lab/Autocomplete';
import {KeyboardDatePicker} from '@material-ui/pickers';
import React, {useState} from 'react';
import {ISkill, Position} from '../../shared/apiModels';
import {FormOptions} from '../data/formOptions';
import InfoIcon from '@material-ui/icons/Info';

const style: any = {
  margin: '10px 5px 10px 5px'
};

export interface IOption {
  label: string;
  value: string | number;
}

interface IGridProps {
  xs?: GridSize;
  sm?: GridSize;
  md?: GridSize;
  lg?: GridSize;
  xl?: GridSize;
}

interface IProps extends IGridProps {
  name: string;
  label: string;
  helperText?: string;
  readOnly?: boolean;

  // values: any;

  getError: (name: string) => string;
  getValue: (name: string) => any;
  setFieldValue: (name: string, value: any) => void;
  handleBlur: (e: any) => void;
}

interface ISelectProps extends IProps {
  options: IOption[];
}
interface IAutoCompleteProps extends IProps {
  options: string[];
}

export function TitanToolTip(props: {text: string; placement: 'left' | 'right'}) {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        title={props.text}
        placement={props.placement}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
      >
        <IconButton onClick={handleTooltipOpen}>
          <InfoIcon color={'primary'} />
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
}

interface IFieldProps extends IProps {
  multiline?: boolean;
  type: 'text' | 'number';
  isPercent?: boolean;
}
export function TitanField(props: IFieldProps) {
  const handleChange = (val: string) => {
    if (props.type === 'number') {
      props.setFieldValue(props.name, val === '' ? null : parseFloat(val));
    } else {
      props.setFieldValue(props.name, val || '');
    }
  };

  const variant = 'outlined';
  const error = props.getError(props.name);

  const inputProps: Partial<OutlinedInputProps> = {
    startAdornment: props.isPercent && <InputAdornment position="start">%</InputAdornment>,
    endAdornment: props.helperText && (
      <InputAdornment position="end">
        <TitanToolTip text={props.helperText} placement={'left'} />
      </InputAdornment>
    )
  };

  return (
    <Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
      <TextField
        label={props.label}
        name={props.name}
        value={props.getValue(props.name) || ''}
        variant={variant}
        error={error ? true : false}
        helperText={error}
        onChange={(e) => handleChange(e.target.value)}
        onBlur={props.handleBlur}
        fullWidth={true}
        style={style}
        multiline={props.multiline}
        disabled={props.readOnly}
        type={props.type}
        InputProps={inputProps}
      />
    </Grid>
  );
}

export function TitanTextField(props: IProps & {multiline?: boolean}) {
  return <TitanField type="text" {...props} />;
}

export function TitanNumberField(props: IProps & {isPercent?: boolean}) {
  return <TitanField type="number" {...props} />;
}

export class TitanDateField extends React.Component<IProps, {}> {
  handleChange(val: string) {
    this.setState({value: val});
    this.props.setFieldValue(this.props.name, val);
  }

  render() {
    const name = this.props.name;
    const error = this.props.getError(this.props.name);

    return (
      <Grid item xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} xl={this.props.xl}>
        <KeyboardDatePicker
          disableToolbar
          variant={'inline'}
          format="MM/DD/yyyy"
          margin="normal"
          name={name}
          label={this.props.label}
          value={this.props.getValue(this.props.name)}
          onChange={(date, value) => this.handleChange(value)}
          onBlur={this.props.handleBlur}
          error={error ? true : false}
          helperText={error}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
          style={style}
          fullWidth={true}
          disabled={this.props.readOnly}
        />
      </Grid>
    );
  }
}

export function TitanSwitch(props: IProps) {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setFieldValue(props.name, event.target.checked);
  };

  return (
    <Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
      <Switch
        checked={props.getValue(props.name) || false}
        onChange={handleChange}
        color="primary"
        name={props.name}
        inputProps={{'aria-label': 'primary checkbox'}}
      />
    </Grid>
  );
}

export function TitanSelectField(props: ISelectProps) {
  const error = props.getError(props.name);
  const labelId = `${props.name}-label`;

  return (
    <Grid item xs={props.xs} sm={props.sm} md={props.md} lg={props.lg} xl={props.xl}>
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel id={labelId}>{props.label}</InputLabel>
        <Select
          labelId={labelId}
          name={props.name}
          error={error ? true : false}
          onChange={(e, data) => props.setFieldValue(props.name, e.target.value)}
          onBlur={props.handleBlur}
          label={props.label}
          fullWidth={true}
          style={style}
          value={props.getValue(props.name) || ''}
          disabled={props.readOnly}
        >
          <MenuItem value="" key={0}>
            <em>None</em>
          </MenuItem>
          {props.options.map((o, index) => (
            <MenuItem value={o.value} key={index + 1}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
        {error ? <div>{error}</div> : null}
      </FormControl>
    </Grid>
  );
}

export class TitanAutoCompleteField extends React.Component<IAutoCompleteProps, {}> {
  handleChange(val: string) {
    const v = val || '';
    this.setState({value: v});
    this.props.setFieldValue(this.props.name, v);
  }

  render() {
    const name = this.props.name;
    const error = this.props.getError(name);

    if (this.props.readOnly) {
      // TODO,HACK: This component doesn't show existing data - need to fix
      return (
        <TitanTextField
          name={name}
          label={this.props.label}
          getValue={this.props.getValue}
          getError={this.props.getError}
          handleBlur={this.props.handleBlur}
          setFieldValue={this.props.setFieldValue}
          readOnly={this.props.readOnly}
          xs={6}
        />
      );
    }

    return (
      <Grid item xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} xl={this.props.xl}>
        <Autocomplete
          freeSolo
          options={this.props.options}
          onChange={(e, v) => this.handleChange(v)}
          onBlur={this.props.handleBlur}
          fullWidth={true}
          renderInput={(params) => (
            <TextField
              {...params}
              name={name}
              label={this.props.label}
              value={this.props.getValue(this.props.name)}
              variant={'outlined'}
              error={error ? true : false}
              helperText={error}
              onChange={(e) => this.handleChange(e.target.value)}
              onBlur={this.props.handleBlur}
              fullWidth={true}
              style={style}
            />
          )}
          // disabled={this.props.readOnly}
        />
      </Grid>
    );
  }
}

export class TitanSkillField extends React.Component<IAutoCompleteProps, {}> {
  render() {
    const name = this.props.name;

    return (
      <React.Fragment>
        <TitanAutoCompleteField
          name={`${name}.name`}
          label={this.props.label}
          getValue={this.props.getValue}
          getError={this.props.getError}
          handleBlur={this.props.handleBlur}
          setFieldValue={this.props.setFieldValue}
          readOnly={this.props.readOnly}
          options={this.props.options}
          xs={6}
        />
        <TitanSelectField
          name={`${name}.level`}
          label="Level"
          getValue={this.props.getValue}
          getError={this.props.getError}
          handleBlur={this.props.handleBlur}
          setFieldValue={this.props.setFieldValue}
          readOnly={this.props.readOnly}
          options={FormOptions.skill}
          xs={6}
        />
      </React.Fragment>
    );
  }
}

type TitanPositionFieldProps = Omit<IProps, 'label'>;

export class TitanPositionField extends React.Component<TitanPositionFieldProps, {}> {
  render() {
    const name = this.props.name;

    const position = this.props.name.endsWith('1') ? 'Most Recent Position' : 'Previous Position';

    return (
      <Grid item xs={12}>
        <Paper>
          <Box paddingLeft={1} paddingRight={2}>
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{position}</Typography>
              </Grid>
              <TitanTextField
                name={`${name}.accomplishments`}
                label="Accomplishments"
                multiline={true}
                getValue={this.props.getValue}
                getError={this.props.getError}
                handleBlur={this.props.handleBlur}
                setFieldValue={this.props.setFieldValue}
                readOnly={this.props.readOnly}
                helperText="Please list two or more accomplishments you had in this role, and remember to offer specific numbers when able"
                xs={12}
                lg={4}
              />
              <TitanTextField
                name={`${name}.projects`}
                label={`Relevant Projects`}
                multiline={true}
                getValue={this.props.getValue}
                getError={this.props.getError}
                handleBlur={this.props.handleBlur}
                setFieldValue={this.props.setFieldValue}
                readOnly={this.props.readOnly}
                xs={12}
                lg={4}
                helperText="Please provide one to two sentence explanations of any projects or work done in this role that is relevant to the role to which you are applying"
              />
              <TitanTextField
                name={`${name}.skills`}
                label={`Additional Information (Optional)`}
                multiline={true}
                getValue={this.props.getValue}
                getError={this.props.getError}
                handleBlur={this.props.handleBlur}
                setFieldValue={this.props.setFieldValue}
                readOnly={this.props.readOnly}
                xs={12}
                lg={4}
                helperText="Please share any other information pertaining to this role which you think we may find important or interesting"
              />
            </Grid>
          </Box>
        </Paper>
      </Grid>
    );
  }
}

interface TitanReadOnlyPositionFieldProps {
  name: string;
  value: Position;
}
export const TitanReadOnlyPositionField = (props: TitanReadOnlyPositionFieldProps) => {
  const {name, value} = props;

  if (!value.projects && !value.skills && !value.accomplishments) {
    return null;
  }

  const position = name.endsWith('1') ? 'Most Recent Position' : 'Previous Position';

  return (
    <>
      <Grid item xs={12}>
        <Paper>
          <Box paddingLeft={1} paddingRight={2}>
            <Grid container item spacing={3}>
              <Grid item xs={12}>
                <Typography variant="h5">{position}</Typography>
              </Grid>
              <TitanReadOnlyText
                name={`${name}.accomplishments`}
                label="Accomplishments"
                multiline={true}
                value={value.accomplishments}
                xs={12}
                lg={4}
              />
              <TitanReadOnlyText
                name={`${name}.projects`}
                label={`Relevant Projects`}
                multiline={true}
                value={value.projects}
                xs={12}
                lg={4}
              />
              <TitanReadOnlyText
                name={`${name}.skills`}
                label={`Additional Information (Optional)`}
                multiline={true}
                value={value.skills}
                xs={12}
                lg={4}
              />
            </Grid>
          </Box>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        {/* I am a spacer */}
      </Grid>
    </>
  );
};

export interface IReadonlySkillProps {
  name: string;
  label: string;
  value: ISkill;
}
export class TitanReadOnlySkill extends React.Component<IReadonlySkillProps, {}> {
  render() {
    const name = this.props.name;

    if (!this.props.value || !this.props.value.name) {
      return null;
    }

    const level = FormOptions.skill.find((x) => x.value === this.props.value.level);

    return (
      <React.Fragment>
        <TitanReadOnlyText name={`${name}.name`} label={this.props.label} value={this.props.value.name} xs={6} />
        <TitanReadOnlyText name={`${name}.level`} label="level" value={level?.label} xs={6} />
      </React.Fragment>
    );
  }
}

export interface IReadonlyTextProps extends IGridProps {
  name: string;
  label: string;
  value: string;
  multiline?: boolean;
}
export class TitanReadOnlyText extends React.Component<IReadonlyTextProps, {}> {
  render() {
    if (!this.props.value) {
      return null;
    }

    return (
      <Grid item xs={this.props.xs} sm={this.props.sm} md={this.props.md} lg={this.props.lg} xl={this.props.xl}>
        <TextField
          label={this.props.label}
          name={this.props.name}
          value={this.props.value}
          variant="outlined"
          fullWidth={true}
          style={style}
          multiline={this.props.multiline}
          disabled={true}
          className="high-contrast"
        />
      </Grid>
    );
  }
}
