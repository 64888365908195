import { Box, Grid, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../contexts/appContext";
import { TitanWideMenu } from "./titanWideMenu";

export function Header() {
  const ctx = useContext(AppContext);

  return (
    <Grid container item xs={12} className="header">
      <Grid item xs={12} sm={6} >
        <Box
          display="flex"
          alignItems="center"
          p={1}
          m={1}>
          <Box>
            <Link to="/">
              <img src="/logo.png" alt="Titan ATS" className="logo" height={"50px"} />
            </Link>
          </Box>
          <Box m={1}>
            <Typography component='h1' variant={'h5'}>{ctx.claims ? ctx.claims.company_name : "Titan ATS"}</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box marginTop={2}>
          <TitanWideMenu />
        </Box>
      </Grid>
    </Grid>
  );
}