import React, {Component} from 'react';
import AppContext, {IAppContext} from './appContext';
import ConfigurationService from '../services/configurationService';
import AmplifyService from '../services/amplifyService';
import {getLogger, Logger} from '../services/loggingService';
import {Auth, Hub} from 'aws-amplify';
import {AppSnackbar} from '../components/base/appSnackbar';
import {AppAlert} from '../models/appAlert';
import {Color} from '@material-ui/lab/Alert';
import {ApiClients} from '../services/api/apiClients';

interface IState {
  status: 'Loading' | 'Authenticating' | 'Authentication Failed' | 'Ready';
  context: IAppContext;
  alert: AppAlert;
}
export class AppContextProvider extends Component<{}, IState> {
  logger: Logger;
  cfg: ConfigurationService;

  constructor(props: any) {
    super(props);
    this.logger = getLogger('AppContextProvider');

    this.cfg = new ConfigurationService();

    this.state = {
      status: 'Loading',
      alert: null,
      context: null
    };

    this.authListener = this.authListener.bind(this);
    this.setAlert = this.setAlert.bind(this);
    this.logout = this.logout.bind(this);
  }

  async componentDidMount() {
    try {
      await this.cfg.load();
      Hub.listen('auth', this.authListener);

      const claims = await AmplifyService.getClaims();

      this.setState({
        ...this.state,
        status: 'Ready',
        context: {
          claims: claims,
          config: this.cfg,
          api: new ApiClients(this.cfg.apiUrl),
          setAlert: this.setAlert,
          handleError: this.handleError,
          logout: this.logout
        }
      });

      if (window.location.href.indexOf('?code=') > -1) {
        this.logger.debug('Processing authentication.');
      }
    } catch (error) {
      this.logger.error('Configuration Failed');
      this.handleError(error);
    }
  }

  setAlert(message: string, type: Color) {
    this.setState({
      ...this.state,
      alert: new AppAlert(message, type)
    });
  }

  handleError(error: any): void {
    if (error.response) {
      // Request made and server responded
      const { status, data } = error.response;
      if (status === 400) {
        const m = Object.entries(data)
          .map((x) => `${x[0]}: ${x[1]}`)
          .join(';');
        this.setAlert(m, 'error');
        return;
      }

      if (data.message) {
        this.setAlert(`Error(${status}): ${data.message}`, 'error');
        return;
      }

      const message =
        typeof data === 'string' ? data : data ? JSON.stringify(data) : '';
      this.setAlert(`Error(${status}): ${message}`, 'error');
    } else if (error.request) {
      this.setAlert('Server did not respond to request', 'error');
    } else {
      // Something happened in setting up the request that triggered an Error
      this.setAlert(error.message, 'error');
    }
  };

  async logout(): Promise<void> {
    await Auth.signOut();
    this.setState({...this.state, context: {...this.state.context, claims: null}});
  }

  async authListener(data: any) {
    this.logger.debug('authListener event', data.payload.event, data);
    switch (data.payload.event) {
      case 'signIn':
        const claims = await AmplifyService.getClaims();
        this.setState({...this.state, status: 'Ready', context: {...this.state.context, claims: claims}});
        break;
      case 'signIn_failure':
        this.logger.error('signIn_failure', JSON.stringify(data.payload));
        this.setState({...this.state, status: 'Authentication Failed'});
        break;
      default:
        break;
    }
  }

  render() {
    if (!this.state.context?.config) {
      return (
        <div>
          <p>{this.state.status}</p>
        </div>
      );
    }

    return (
      <AppContext.Provider value={this.state.context}>
        <AppSnackbar alert={this.state.alert} clear={() => this.setAlert('', 'success')} />
        {this.props.children}
      </AppContext.Provider>
    );
  }
}
