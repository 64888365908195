import {useContext, useEffect, useState} from 'react';
import {IUser} from '../shared/apiModels';
import AppContext from '../contexts/appContext';
import {getLogger} from '../services/loggingService';

export const useUsers = (refresh: Date) => {
  const context = useContext(AppContext);
  const logger = getLogger('useUsers');

  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<IUser[]>([]);
  const [error, setError] = useState('');

  async function fetchData() {
    try {
      setLoading(true);
      const data = await context.api.user.getUsers();
      setResult(data);
    } catch (error) {
      logger.error(error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return {
    error,
    loading,
    result
  };
};
