import React from "react";
import "./app.css";
import { AppContextProvider } from "./contexts/appContextProvider";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { createMuiTheme, Grid, MuiThemeProvider } from "@material-ui/core";
import Footer from "./components/base/footer";
import Logout from "./components/pages/logout";

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Registration from "./components/pages/registration";
import { ApplicantProfile } from "./components/ApplicantProfile/applicantProfile";
import { ApplicantProfileCreate } from "./components/ApplicantProfile/applicantProfileCreate";
import Jobs from "./components/pages/jobs";
import { ApplicantProfileUnlock } from "./components/ApplicantProfile/applicantProfileUnlock";
import { SsoContainer } from "./components/pages/ssoContainer";
import ProtectedRoute from "./components/base/protectedRoute";
import { ApplicantProfileList } from "./components/ApplicantProfile/applicantProfileList";
import { Settings } from "./components/Settings/settings";
import { Header } from "./components/base/header";

const mainColor = '#68c6b4';
const contrastColor = '#297d6c';
const theme = createMuiTheme({
  palette: {
    primary: {
      main: mainColor,
    },
  },
  overrides:{
    MuiLink:{
      root: {
        color: contrastColor
      }
    },
    MuiBottomNavigationAction:{
      label:{
        color: contrastColor
      }
    },
    MuiInputLabel:{
      root: {
        color: contrastColor,
      }
    },
  }
});

export default class App extends React.Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Grid container className="App" role="main">
          <Router>
            <AppContextProvider>
              <Header />
              <Grid container item className="content" justify={"center"} alignItems={"flex-start"}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <Switch>
                    <Route path="/logout" component={Logout} />
                    <Route path="/register" component={Registration} />
                    <Route path="/apply/:siteUrl/:id" component={ApplicantProfileCreate} />
                    <Route path="/unlock" component={ApplicantProfileUnlock} />
                    <ProtectedRoute path="/profile" component={ApplicantProfile} />
                    <ProtectedRoute path="/jobs" component={Jobs} />
                    <ProtectedRoute path="/settings" admin={true} component={Settings} />
                    <ProtectedRoute path="/sso" component={SsoContainer} />
                    <ProtectedRoute path="/:jobId?" component={ApplicantProfileList} />
                  </Switch>
                </MuiPickersUtilsProvider>
              </Grid>
              <Footer />
            </AppContextProvider>
          </Router>
        </Grid>
      </MuiThemeProvider>
    );
  }
}
