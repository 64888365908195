import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, GridSize, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from "@material-ui/core";
import { useFormik, getIn } from "formik";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../contexts/appContext";
import { useCompanySettings } from "../../hooks/useCompanySettings";
import { getLogger } from "../../services/loggingService";
import { CompanySettings } from "../../shared/apiModels";
import { CompanySettingsSchema } from "../../shared/apiValidation";
import { TitanSwitch, TitanTextField, TitanToolTip } from "../base/titanFormInput";
import { TitanLoading } from "../base/titanLoading";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';


const logger = getLogger("CompanySettings");


const deiSize = {
  xs: 12 as GridSize,
  sm: 6 as GridSize,
}

export function CompanySettingsComponent() {

  const [readOnly, setReadOnly] = useState(false);
  const [open, setOpen] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState<string>();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editMessageName, setEditMessageName]  = useState<string>();
  const [editMessageBody, setEditMessageBody] = useState<string>();
  const [messageToEdit, setMessageToEdit] = useState<string>();

  const ctx = useContext(AppContext);
  const { handleBlur, setFieldValue, handleSubmit, values, errors, touched, setValues } = useFormik<CompanySettings>({
    initialValues: new CompanySettings(),
    validationSchema: CompanySettingsSchema,
    onSubmit: async (values) => {
      try {
        setReadOnly(true);
        logger.debug(values);
        await ctx.api.company.updateSettings(values);
        ctx.setAlert("Settings updated", "success");
      } catch (e) {
        ctx.handleError(e);
      } finally {
        setReadOnly(false);
      }
    },
  });

  const addMessage = async () =>{
    if(!values.preloadedMessages) values.preloadedMessages = [];
    values.preloadedMessages.push({name: formProps.getValue("messageName"), body: formProps.getValue("messageBody")});
    setValues(values); 
    await ctx.api.company.updateSettings(values);
    ctx.setAlert("Messaged added", "success");
    formProps.setFieldValue("messageName", "");
    formProps.setFieldValue("messageBody", "");
  }

  const [refresh] = useState(new Date());
  const settings = useCompanySettings(refresh);
  useEffect(() => {
    if (settings.result) {
      setValues(settings.result);
    }
  }, [setValues, settings.result]);

  /**
 * Returns error text if touched. Works with nested objects.
 */
  const getError = (name: string): string => {
    return getIn(touched, name) && getIn(errors, name)
      ? getIn(errors, name)
      : "";
  };

  const formProps = {
    getError,
    getValue: (name: string) => getIn(values, name),
    handleBlur,
    setFieldValue,
    readOnly
  }

  const openDeleteMessage = (msg: string) => {
    setMessageToDelete(msg);
    setOpen(true);
  }

  const openEditModalHandler = (msgName: string, msgBody: string) => {
    setEditMessageName(msgName);
    setEditMessageBody(msgBody);
    setMessageToEdit(msgName);
    setOpenEditModal(true);
  }

  const deleteMessage = async (e: any) => {
    values.preloadedMessages = values.preloadedMessages.filter((preloadMessage) => {
      return preloadMessage.name !== messageToDelete;
      });
    setValues(values);
    setOpen(false);
    await ctx.api.company.updateSettings(values);
    ctx.setAlert("Message deleted", "success");
  }

  const handleDialogClose = () => {
    setOpen(false);
  }

  const handleEditMessageClose = () => {
    setOpenEditModal(false);
  }

  const handleEditMessageSave = async () => {
    console.log(messageToEdit);
    values.preloadedMessages = values.preloadedMessages.filter((message) => {
      console.log(message);
      if(message.name === messageToEdit){
        message.name = editMessageName;
        message.body = editMessageBody;
      }
      return message;
    });
    console.log(values.preloadedMessages);
    setValues(values);
    setOpenEditModal(false);
    await ctx.api.company.updateSettings(values);
    ctx.setAlert("Message updated", "success");

  }
  
  const handleMessageNameChange = (e: any) => {
    setEditMessageName(e.target.value);
  }

  const handleMessageBodyChange = (e: any) => {
    setEditMessageBody(e.target.value);
  }

  const renderConfirmDialog = () => {
    return <>
        <Dialog
        open={open}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the message?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={()=> setOpen(false)}>Cancel</Button>
          <Button variant="contained" color="primary" onClick={deleteMessage} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
      </>
  }

  const renderEditDialog = () => {
    return (
      <Dialog open={openEditModal} onClose={handleEditMessageClose}>
      <DialogTitle>Edit Message</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Message Name"
          type="text"
          fullWidth
          variant="standard"
          value={editMessageName}
          onChange={handleMessageNameChange}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Message Body"
          type="text"
          fullWidth
          variant="standard"
          value={editMessageBody}
          onChange={handleMessageBodyChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleEditMessageClose} variant="outlined">Cancel</Button>
        <Button onClick={handleEditMessageSave} color="primary" variant="contained">Save</Button>
      </DialogActions>
    </Dialog>
    )
  }

  const renderContent = () => {

    if (settings.loading) {
      return (<TitanLoading />);
    }

    return (<Grid item xs={12} >
      <Grid container spacing={1}>
        <TitanTextField
          name="messageFooter"
          label="Email Message Footer"
          multiline={true}
          xs={12}
          {...formProps}
        />
        <TitanTextField
          name="confirmationFooter"
          label="Confirmation Message Footer"
          multiline={true}
          xs={12}
          {...formProps}
        />
        <TitanTextField
          name="rejectionMessage"
          label="Rejection Message"
          multiline={true}
          xs={12}
          {...formProps}
        />
        <TitanTextField
          name="shareProfileMessage"
          label="Share Profile Message Template"
          multiline={true}
          xs={12}
          {...formProps}
        />
        <TitanTextField
          name="interviewMessage"
          label="Interview Invitation Message Template"
          multiline={true}
          xs={12}
          {...formProps}
        />

        <Typography
          component="span"
          variant="h5"
          color="textPrimary">
          Custom Messages
          <TitanToolTip text="Preloaded messages to send to the candidates" placement={"right"} />
        </Typography>

        <TitanTextField
          name="messageName"
          label="Message Name"
          multiline={false}
          xs={12}
          {...formProps}
        />
        <TitanTextField
          name="messageBody"
          label="Message Body"
          multiline={true}
          xs={12}
          {...formProps}
        />

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            className="left-button"
            onClick={() => addMessage()}
            disabled={readOnly}
          >Add</Button>
        </Grid>
        <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
             <TableCell
                  key="name"
                >
                  Name
                </TableCell>
                <TableCell
                  key="body"
                >
                  Body
                </TableCell>
                <TableCell
                  key="actions"
                >
                </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              values.preloadedMessages?.map((row) => {
                return (
                  <TableRow hover key={row.name}>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{row.body}</TableCell>
                      <TableCell align="right">
                        <Tooltip title="Delete Message">
                          <IconButton onClick={() => openDeleteMessage(row.name)} >
                            <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Edit Message">
                          <IconButton onClick={() => openEditModalHandler(row.name, row.body)} >
                            <EditIcon />
                          </IconButton>
                        </Tooltip>
                          </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
        <Typography
          component="span"
          variant="h5"
          color="textPrimary">
          Diversity, Equity, and Inclusion Goals
          <TitanToolTip text="These self-reported goals and metrics will be visible to candidates when they apply" placement={"right"} />
        </Typography>

        <TitanSwitch
          name="deiGoals.enabled"
          label="Enabled"
          xs={12}
          {...formProps}
        />

        {formProps.getValue("deiGoals.enabled") &&
          <React.Fragment>
            <TitanTextField
              name="deiGoals.missionStatement"
              label="Mission Statement"
              multiline={true}
              xs={12}
              {...formProps}
            />

            <TitanTextField
              name="deiGoals.companySurveyResultsLink"
              label="Company Diversity Survey Results Link"
              {...deiSize}
              {...formProps}
            />
            <TitanTextField
              name="deiGoals.executiveSurveyResultsLink"
              label="Executive Diversity  Survey Results Link"
              {...deiSize}
              {...formProps}
            />

            <TitanTextField
              name="deiGoals.candidateSurveyResultsLink"
              label="Candidate Survey Results Link"
              {...deiSize}
              {...formProps}
            />
            <TitanTextField
              name="deiGoals.employeeSurveyResultsLink"
              label="Employee Survey Results Link"
              {...deiSize}
              {...formProps}
            />

            <TitanTextField
              name="deiGoals.goal1.goalStatement"
              label="Goal"
              multiline={true}
              xs={5}
              {...formProps}
            />
            <TitanTextField
              name="deiGoals.goal1.actionTaken"
              label="Action Taken"
              multiline={true}
              xs={5}
              {...formProps}
            />
            <TitanTextField
              name="deiGoals.goal1.percentComplete"
              label="Percent Complete"
              xs={2}
              {...formProps}
            />
          </React.Fragment>}

        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            className="left-button"
            onClick={() => handleSubmit()}
            disabled={readOnly}
          >Update</Button>
        </Grid>
      </Grid>
    </Grid>)
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          component="span"
          variant="h4"
          color="textPrimary"
        >
          Company Settings
          </Typography>
      </Grid>
      {renderContent()}
      {renderConfirmDialog()}
      {renderEditDialog()}
    </Grid>
  );
}