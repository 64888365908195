import {CognitoIdToken} from 'amazon-cognito-identity-js';
import {Auth} from 'aws-amplify';
import {IToken} from '../shared/apiModels';
import {getLogger} from './loggingService';

const logger = getLogger('AmplifyService');

/**
 * Provides helpers for working with Amplify
 */
export default abstract class AmplifyService {
  public static async sdkCredentials(): Promise<any> {
    try {
      const credentials = await Auth.currentUserCredentials();
      return Auth.essentialCredentials(credentials);
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  public static async isAuthenticated(): Promise<boolean> {
    try {
      await Auth.currentAuthenticatedUser();
      return true;
    } catch (error) {
      logger.error('isAuthenticated()', error);
      return false;
    }
  }

  public static async getUserId(): Promise<string> {
    const user = (await Auth.currentSession()).getIdToken().decodePayload();

    let email = null;
    if (user.identities && user.identities.length > 0) {
      // Federated Auth
      email = user.identities[0].userId;
    } else {
      // User Pool Auth
      email = user.email;
    }

    return email;
  }

  /**
   * Initiates federated sign-in. When custom provider is passed in, will redirect to IDP without showing hosted ui.
   * @param customProvider Cognito Identity Provider Id
   */
  public static federatedLogin(customProvider?: string) {
    if (customProvider) {
      Auth.federatedSignIn({
        customProvider
      });
    } else {
      Auth.federatedSignIn();
    }
  }

  public static async getAccessJwtToken(): Promise<string> {
    const session = Auth.currentSession();
    const accessToken = (await session).getAccessToken();
    const jwtToken = accessToken.getJwtToken();
    return jwtToken;
  }

  public static async getIdToken(): Promise<CognitoIdToken> {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      return idToken;
    } catch (error) {
      logger.error(error);
      throw error;
    }
  }

  public static async getClaims(): Promise<IToken> {
    try {
      const session = await Auth.currentSession();
      const idToken = session.getIdToken();
      const payload = idToken.decodePayload();
      return {
        user_id: payload.user_id,
        username: payload['cognito:username'],
        email: payload.email,
        groups: payload['cognito:groups'],
        name: payload.name,
        company_id: payload.company_id,
        company_name: payload.company_name,
        titan_company_id: payload.titan_company_id,
        site_url: payload.site_url,
        admin_level: payload.admin_level
      };
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}
