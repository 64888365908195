import {Moment} from 'moment';
import {ITokenClaims} from './cognitoModels';

export interface IRegister {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  company: string;
  city: string;
  state: string;
  siteUrl: string;
}

/**
 * Unique App Id
 */
export interface IAppId {
  companyId: string;
  jobId: string;
  applicationId: string;
}

export type ApplicantProfileLockStatus = 'locked' | 'pending' | 'unlocked' | 'declined';

/**
 * Partial profile record - used in lists
 */
export interface IApplicantProfileList extends IAppId {
  jobTitle: string;
  headline: string;
  averageTenure: string;
  startAvailability: Moment;
  appliedAt: Date;
  status: string;
  lockStatus: ApplicantProfileLockStatus;
}

/**
 * Full applicant profile record
 */
export interface IApplicantProfile extends IAppId {
  siteUrl: string;
  jobTitle: string;

  // User Entered Form Fields
  headline: string;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  problemsSolved: string;
  position1: Position;
  position2: Position;
  position3: Position;
  startAvailability?: Moment;
  averageTenure: string;
  education: string;
  volunteer: string;
  skill1: ISkill;
  skill2: ISkill;
  skill3: ISkill;
  skill4: ISkill;
  additionalSkills: string;
  coverLetter: string;

  notes?: IApplicationNote[];
  messages?: IApplicationMessage[];

  lockStatus: ApplicantProfileLockStatus;
  unlockRequestedBy?: string;
  unlockRequestedAt?: number;
  unlockUpdatedAt?: number;

  rejectMessageSent?: boolean;

  // Retrieved from ATS
  status?: string;

  // Deprecated Fields
  description1?: string;
  description2?: string;
  description3?: string;
}

export interface ISkill {
  name: string;
  level: string;
}

export class Position {
  projects = '';
  skills = '';
  accomplishments = '';
}

export interface IJobPublicInfo {
  companyId: string;
  title: string;
  description: string;
  abstract: string;
  visible: boolean;
  skills: string[];
}
export interface IJobDetails {
  id: string;
  company_id: string;
  title: string;
  abstract: string;
  status: string;
  joblink: string;
  active: boolean;
  visible: boolean;
  posted_at: Date;
  total_apps: number;
  open_positions_count: number;
  tags: string[];
}

export interface IApplicationNote {
  id: string;
  userDisplayName: string;
  note: string;
  createdAt: number;
}

export interface IApplicationMessage {
  body: string;
  createdBy: string;
  createdAt: string;
}

export type AdminLevel = 'AccountOwner' | 'Admin' | 'Basic' | 'Limited' | 'ReadOnly';

export interface IUser {
  id?: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  admin_level: AdminLevel;
}

export interface ISendEmail extends IAppId {
  sender: string;
  recipient: string;
  subject: string;
  body: string;
  siteUrl: string;

}

export interface INoteRequest extends IAppId {
  text: string;
}

export interface IMessageRequest extends IAppId {
  text: string;
}

export interface IStatusUpdate extends IAppId {
  status: string;
}

export interface IToken extends ITokenClaims {
  email: string;
  groups?: string[];
  username: string;
}

export interface IUnlockRequest {
  signedLink: string;
}

export class DeiGoals {
  enabled: boolean;
  missionStatement: string;

  goal1: DeiGoal;
  goal2: DeiGoal;
  goal3: DeiGoal;
  goal4: DeiGoal;
  goal5: DeiGoal;

  companySurveyResultsLink: string;
  executiveSurveyResultsLink: string;
  candidateSurveyResultsLink: string;
  employeeSurveyResultsLink: string;

  constructor() {
    this.goal1 = new DeiGoal();
    this.goal2 = new DeiGoal();
    this.goal3 = new DeiGoal();
    this.goal4 = new DeiGoal();
    this.goal5 = new DeiGoal();
  }
}

export class DeiGoal {
  goalStatement: string;
  actionTaken: string;
  percentComplete: number;
}

export class PreloadedMessages {
  name: string;
  body: string;
}

export class CompanySettings {
  /**Appended to emails */
  messageFooter: string;

  /**Appended to application confirmation email */
  confirmationFooter: string;

  /**Can be sent when candidate is not qualified */
  rejectionMessage: string;

  // dei: DeiInfo;
  deiGoals: DeiGoals;

  //preloaded messages to send to the applicant
  preloadedMessages: PreloadedMessages[];

  //share profile template
  shareProfileMessage: string;

  //interview message template
  interviewMessage: string;

  constructor() {
    // this.dei = new DeiInfo();
    this.deiGoals = new DeiGoals();
    this.preloadedMessages = [];
  }
}
