import { LinearProgress } from "@material-ui/core";
import React from "react";

export function TitanLoading(){
  return (
    <div className="loading-spinner">
      <LinearProgress />
      <p>Loading...</p>
    </div>
  );
};
