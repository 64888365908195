import {Color} from '@material-ui/lab';
import React from 'react';
import {IToken} from '../shared/apiModels';
import ConfigurationService from '../services/configurationService';
import {ApiClients} from '../services/api/apiClients';

export interface IAppContext {
  /**
   * Will be null if user not authenticated.
   */
  claims: IToken;
  config: ConfigurationService;
  api: ApiClients;
  setAlert: (message: string, type: Color) => void;
  handleError: (error: any) => void;
  logout: () => void;
}

const AppContext = React.createContext(null as IAppContext);

export default AppContext;
