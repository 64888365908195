import { FormControl, InputLabel, Select, MenuItem, Grid, Button } from "@material-ui/core";
import React, { useContext, useState } from "react";
import AppContext from "../../contexts/appContext";
import { IAppId } from "../../shared/apiModels";
import { FormOptions } from "../data/formOptions";

interface IProps {
  appId: IAppId;
  status: string;
  // readOnly: boolean;
  rejectionSent: boolean;
}
export function Disposition(props: IProps){

  const [rejectionSent, setRejectionSent] = useState(props.rejectionSent);

  const [options] = useState(FormOptions.disposition);
  const [value, setValue] = useState(props.status);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const ctx = useContext(AppContext);

  const handleChange = async (val: string) => {
    const status = val || "";
    setValue(status);

    try {
      setLoading(true);
      setError("");

      await ctx.api.applications.updateStatus({
        ...props.appId,
        status: status,
      });

    } catch (error) {
      setError(error.message);
    } finally{
      setLoading(false);
    }
  }

  const sendRejection = async ()=>{
    try {
      setLoading(true);
      setError("");

      await ctx.api.applications.sendRejection(props.appId);
      setRejectionSent(true);
      ctx.setAlert("Rejection message sent", "success");
    } catch (error) {
      setError(error.message);
    } finally{
      setLoading(false);
    }
  }

  return (
    <Grid item xs={7} sm={6} md={3}>
      <FormControl variant="outlined" fullWidth={true}>
        <InputLabel id={"disposition-label"}>Disposition</InputLabel>
        <Select
          labelId={`disposition-label`}
          name="disposition"
          onChange={(e, data) => handleChange(e.target.value as string)}
          label="Status"
          fullWidth={true}
          value={value}
          disabled={loading}
        >
          <MenuItem value="" key={0}>
            <em>None</em>
          </MenuItem>
          {options.map((o, index) => (
            <MenuItem value={o.value} key={index + 1}>
              {o.label}
            </MenuItem>
          ))}
        </Select>
        {value === "Not Hired - Not Qualified" && !rejectionSent && <Button color={"secondary"} disabled={loading} onClick={()=>sendRejection()}>
            Send Rejection Email
        </Button>}
        {error ? <div>{error}</div> : null}
      </FormControl>
    </Grid>
  );
}