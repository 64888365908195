import { IJobApi } from "../../shared/apiInterfaces";
import { IJobDetails } from "../../shared/apiModels";
import { ApiClientBase } from "./apiClientBase";
import { getLogger } from "../loggingService";

export class JobApiClient extends ApiClientBase implements IJobApi{

  constructor(apiUrl: string){
    super(apiUrl, getLogger("JobApiClient"), true)
  }

  async getJobs(): Promise<IJobDetails[]> {
    const result = await this.get("/api/jobs");
    const data: IJobDetails[] = await result.json();

    // Transform Zulu time to Date
    data.forEach(x => x.posted_at = new Date(x.posted_at));

    return data;
  }

  async getJob(id: string): Promise<IJobDetails> {
    const result = await this.get(`/api/job?id=${id}`);
    return await result.json();
  }
}