import { IApplicationApi } from "../../shared/apiInterfaces";
import { IApplicantProfileList, IApplicantProfile, INoteRequest, IApplicationNote, IMessageRequest, IApplicationMessage, IStatusUpdate, IAppId, ISendEmail } from "../../shared/apiModels";
import { ApiClientBase } from "./apiClientBase";
import { getLogger } from "../loggingService";

export class ApplicationApiClient extends ApiClientBase implements IApplicationApi {

  constructor(apiUrl: string) {
    super(apiUrl, getLogger("ApplicationApiClient"), true)
  }

  async getApplications(): Promise<IApplicantProfileList[]> {
    const result = await this.get("/api/applications")
    const data: IApplicantProfileList[] = await result.json();

    // Transform Zulu time to Date
    data.forEach(x => x.appliedAt = new Date(x.appliedAt));

    return data;
  }

  async getApplication(jobId: string, applicationId: string): Promise<IApplicantProfile> {
    const result = await this.get(`/api/application?jobId=${jobId}&applicationId=${applicationId}`);
    const profile: IApplicantProfile = await result.json();
    profile.notes = profile.notes || [];
    return profile;
  }

  async createNote(req: INoteRequest): Promise<IApplicationNote> {
    const result = await this.post(`/api/application/note`, req);
    const note: IApplicationNote = await result.json();
    return note;
  }

  async sendMessage(req: IMessageRequest): Promise<IApplicationMessage> {
    const result = await this.post(`/api/application/message`, req);
    const note: IApplicationMessage = await result.json();
    return note;
  }

  async sendRejection(req: IAppId): Promise<IApplicationMessage> {
    const result = await this.post(`/api/application/rejection`, req);
    const note: IApplicationMessage = await result.json();
    return note;
  }

  async updateStatus(req: IStatusUpdate): Promise<{ status: string }> {
    const result = await this.post("/api/application/status", req);
    return await result.json();
  }

  async unlock(req: IAppId): Promise<{ status: string }> {
    const result = await this.post("/api/application/unlock", req);
    return await result.json();
  }

  async sendEmail(req: ISendEmail): Promise<{status: string}> {
    const result = await this.post("/api/application/shareProfile", req);
    return await result.json();
  }
}