import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import Html from 'react-pdf-html';

// Create Document Component
const MyDocument: React.FC<any> = (userData: any) => {
  console.log(userData);
  let htmlTemplate = `<html>
        <body>
       

        <div class="container" style="max-width: 1100px; margin: 10px 50px;">
          <div class="hero" style="">
            <h1 class="name" style="margin: 0; font-size: 30px;"><strong>${userData.userData.lockStatus === 'unlocked' ? `${userData.userData.firstName} ${userData.userData.lastName}` : 'Candidate Profile'}</strong>
            <br/>
            <span style="font-size: 18px; margin: 0">${userData.userData.headline}</span>
            </h1>
            <span class="email" style="display: block; font-size: 18px; float: right; font-weight: 300; color: #0000ed">${userData.userData.lockStatus === 'unlocked' ? `${userData.userData.email}` : 'Email Locked'}</span>
            <br/>
            <span class="lead" style="font-size: 18px; margin: 10px auto">${userData.userData.coverLetter}</span>
          </div>
        </div>
        
        <div class="container" style="max-width: 1100px; margin: 10px 50px;">
        <hr>
        
          <div class="sections" style="">
            <h3 class="section-title" style="margin-bottom: 15px; font-size: 30px;margin-top: 15px"><strong>Skills</strong></h3>
            
            <div class="list-card" style="margin: 10px 0;">
              <div>
                <h3 style="font-size: 14px;">• ${userData.userData.skill1.name}</h3>
                <span class="exp" style="font-size: 10px; margin-left: 10px">${userData.userData.skill1.level === '1' ? 'Beginner' : (userData.userData.skill1.level === '5' ? 'Proficient' : (userData.userData.skill1.level === '10' ? 'Expert' : ''))}</span>              
              </div>
            </div>`;
  if (userData.userData.skill2.name !== '') {
    htmlTemplate = htmlTemplate +
      `<div class="list-card" style="margin: 10px 0;">
              <div>
                <h3 style="font-size: 14px;">• ${userData.userData.skill2.name}</h3>
              </div>
              <span class="exp" style="font-size: 10px; margin-left: 10px">${userData.userData.skill2.level === '1' ? 'Beginner' : (userData.userData.skill2.level === '5' ? 'Proficient' : (userData.userData.skill2.level === '10' ? 'Expert' : ''))}</span>
              </div>`
  }
  if (userData.userData.skill3.name !== '') {
    htmlTemplate = htmlTemplate +
      `<div class="list-card" style="margin: 10px 0;">
              <div>
                <h3 style="font-size: 14px;">• ${userData.userData.skill3.name}</h3>
                <span class="exp" style="font-size: 10px; margin-left: 10px">${userData.userData.skill3.level === '1' ? 'Beginner' : (userData.userData.skill3.level === '5' ? 'Proficient' : (userData.userData.skill3.level === '10' ? 'Expert' : ''))}</span>
              </div>
            </div>`
  }
  if (userData.userData.skill4.name !== '') {
    htmlTemplate = htmlTemplate +
      `<div class="list-card" style="margin: 10px 0;">
              <div>
                <h3 style="font-size: 14px;">• ${userData.userData.skill4.name}</h3>
                <span class="exp" style="font-size: 10px; margin-left: 10px">${userData.userData.skill4.level === '1' ? 'Beginner' : (userData.userData.skill4.level === '5' ? 'Proficient' : (userData.userData.skill4.level === '10' ? 'Expert' : ''))}</span>
              </div>
            </div>`
  }
  if (userData.userData.additionalSkills) {
    htmlTemplate = htmlTemplate +
      `<div class="list-card" style="margin: 30px 0;">
              <div>
                <h3>Other Skills</h3>
                <span class="exp" style="font-size: 10px; margin-left: 10px">${userData.userData.additionalSkills}</span>
              </div>
            </div>`
  }
  htmlTemplate = htmlTemplate +
    `</div>
    <hr>
          <div class="sections" style="margin-top: 15px">
            <h3 class="section-title" style="margin-bottom: 15px;font-size: 30px"><strong>Experience</strong></h3>
`
  if (userData.userData.position1 !== '') {
    htmlTemplate = htmlTemplate +
      `<div class="list-card" style="margin-top: 15px">
              <div>
        <h4>Most Recent Position</h4>
                <span style="font-weight: 1200!important; font-size: 14px; margin-left: 10px;">Accomplishments: </span><span style="font-size: 12px">${userData.userData.position1.accomplishments}</span>
                <br/>
                <span style="font-weight: 900;  font-size: 14px; margin-left: 10px;">Relevant Projects: </span><span style="font-size: 12px">${userData.userData.position1.projects}</span>
                <br/>
                <span style="font-weight: 900;  font-size: 14px; margin-left: 10px;">Skills: </span><span style="font-size: 12px">${userData.userData.position1.skills}</span>
                </div>
            </div>`
  }



  if (userData.userData.position2 !== '') {
    htmlTemplate = htmlTemplate +
      `<div class="list-card" style="margin-top: 15px">
                <div><h4>Previous Position</h4>
                <span style="font-weight: 900;  font-size: 14px; margin-left: 10px;">Accomplishments: </span><span style="font-size: 12px">${userData.userData.position2.accomplishments}</span>
                <br/>
                <span style="font-weight: 900;  font-size: 14px; margin-left: 10px;">Relevant Projects: </span><span style="font-size: 12px">${userData.userData.position2.projects}</span>
                <br/>
                <span style="font-weight: 900;  font-size: 14px; margin-left: 10px;">Skills: </span><span style="font-size: 12px">${userData.userData.position2.skills}</span>
                </div>
              </div>`
  }
  if (userData.userData.position3.projects !== '') {
    htmlTemplate = htmlTemplate +
      `<div class="list-card" style="margin-top: 15px">
                <div>
                  <h4>Previous Position</h4>
                  <span style="font-weight: 900;  font-size: 14px; margin-left: 10px;">Accomplishments: </span><span style="font-size: 12px">${userData.userData.position3.accomplishments}</span>
                  <br/>
                  <span style="font-weight: 900;  font-size: 14px; margin-left: 10px;">Relevant Projects: </span><span style="font-size: 12px">${userData.userData.position3.projects}</span>
                  <br/>
                <span style="font-weight: 900;  font-size: 14px; margin-left: 10px;">Skills: </span><span style="font-size: 12px">${userData.userData.position3.skills}</span>
                </div>
              </div>`
  }

  htmlTemplate = htmlTemplate +
    `</div>
        </div>
        </body>
        </html>`;

  return (<>
    <Document>
      <Page >
        <Html collapse={true} resetStyles>
          {htmlTemplate}
        </Html>
      </Page>
    </Document>
  </>
  );

}

export default MyDocument;