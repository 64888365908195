import * as Yup from 'yup';
import {OptionalObjectSchema} from 'yup/lib/object';

export interface IValidationErrors {
  [name: string]: string[];
}

export interface IValidationResult<TModel> {
  isValid: boolean;
  errors: IValidationErrors;
  /**
   * Validation will remove unknown fields
   */
  data: TModel;
}

export const validateSchema = <TModel>(schema: OptionalObjectSchema<any>, data: TModel): IValidationResult<TModel> => {
  const response: IValidationResult<TModel> = {
    isValid: true,
    errors: {},
    data: undefined
  };
  try {
    response.data = schema.validateSync(data, {abortEarly: false, stripUnknown: true});
  } catch (error) {
    response.isValid = false;
    error.inner.forEach((e: any) => {
      response.errors[e.path] = e.errors;
    });
  }
  return response;
};

const phoneFormat = () => {
  return Yup.string()
    .required('Phone is required')
    .matches(/^\d{1,10}$/, 'Phone must be 10 digits');
};

function percentageFormat() {
  return Yup.number().nullable().min(0).max(100);
}

const emailFormat = () => {
  return Yup.string().lowercase('Email must be lower case').required('Email is required').email();
};

export const RegisterSchema = Yup.object({
  firstName: Yup.string().required('First Name is required').max(80),
  lastName: Yup.string().required('Last Name is required').max(80),
  email: emailFormat(),
  phone: phoneFormat(),
  company: Yup.string().required('Company is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required').max(5),
  siteUrl: Yup.string()
    .required('Site URL is required')
    .matches(
      /^([a-z][a-z0-9]*)(-[a-z0-9]+)*$/,
      'Site URL accepts lowercase letters, digits, and dashes. Example: my-company, company-2'
    )
});

export const PositionSchema = Yup.object({
  projects: Yup.string().max(1024),
  skills: Yup.string().max(1024),
  accomplishments: Yup.string().max(1024),
});

export const SkillSchema = Yup.object({
  name: Yup.string().max(128),
  level: Yup.string().max(128)
});

export const ApplicantProfileSchema = Yup.object({
  companyId: Yup.string().required('Company ID is required'),
  siteUrl: Yup.string().required('Site URL is required'),
  jobId: Yup.string().required('Job ID is required'),
  jobTitle: Yup.string().required('Job Name is required'),
  firstName: Yup.string().required('First Name is required').max(80),
  lastName: Yup.string().required('Last Name is required').max(80),
  email: emailFormat(),
  phone: phoneFormat(),
  headline: Yup.string().required('Headline is required').max(256),
  problemsSolved: Yup.string().required('Problems solved field is required').max(1024),
  position1: PositionSchema.required(),
  position2: PositionSchema.required(),
  position3: PositionSchema.required(),
  startAvailability: Yup.string().required('Start Availability is required').max(125),
  averageTenure: Yup.string().max(125),
  education: Yup.string().required('Education is required').max(10),
  volunteer: Yup.string().max(1024),
  skill1: SkillSchema,
  skill2: SkillSchema,
  skill3: SkillSchema,
  skill4: SkillSchema,
  additionalSkills: Yup.string().max(1024),
  coverLetter: Yup.string().required('This field is required')
});

export const NoteRequestSchema = Yup.object({
  companyId: Yup.string().required('Company ID is required'),
  jobId: Yup.string().required('Job ID is required'),
  applicationId: Yup.string().required('Application ID is required'),
  text: Yup.string().required('Text is required').max(1024)
});

export const UserSchema = Yup.object({
  first_name: Yup.string().required('First Name is required').max(40),
  last_name: Yup.string().required('Last Name is required').max(40),
  email: emailFormat(),
  phone: phoneFormat(),
  admin_level: Yup.string().required('Admin Level is required').oneOf(['AccountOwner', 'Admin', 'Basic'])
});

export const DeiGoalSchema = Yup.object({
  goalStatement: Yup.string().max(2048),
  actionTaken: Yup.string().max(2048),
  percentComplete: percentageFormat()
});

export const PreloadedMessagesSchema = Yup.object({
  name: Yup.string().max(128),
  body: Yup.string().max(2048)
});

export const DeiGoalsSchema = Yup.object({
  enabled: Yup.boolean().required(),
  missionStatement: Yup.string().max(2048),
  goal1: DeiGoalSchema.required(),
  goal2: DeiGoalSchema.required(),
  goal3: DeiGoalSchema.required(),
  goal4: DeiGoalSchema.required(),
  goal5: DeiGoalSchema.required(),
  companySurveyResultsLink: Yup.string(),
  executiveSurveyResultsLink: Yup.string(),
  candidateSurveyResultsLink: Yup.string(),
  employeeSurveyResultsLink: Yup.string()
});

export const CompanySettingsSchema = Yup.object({
  messageFooter: Yup.string().max(2048),
  confirmationFooter: Yup.string().max(2048),
  rejectionMessage: Yup.string().max(2048),
  shareProfileMessage: Yup.string().max(2048),
  interviewMessage: Yup.string().max(2948),
  deiGoals: DeiGoalsSchema.required(),
  preloadedMessages: Yup.array().of(PreloadedMessagesSchema).notRequired()
});
