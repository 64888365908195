import { Button, Grid, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import { getIn, useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { IAppId, IApplicationNote, INoteRequest } from "../../shared/apiModels";
import AppContext from "../../contexts/appContext";
import { getLogger } from "../../services/loggingService";
import { TitanTextField } from "../base/titanFormInput";
import { NoteRequestSchema } from "../../shared/apiValidation";

interface IProps extends IAppId {
  notes: IApplicationNote[]
}
export function Notes(props: IProps){
  const logger = getLogger("Notes");

  const [readOnly, setReadOnly] = useState(false);
  const [notes, setNotes] = useState<IApplicationNote[]>([]);

  useEffect(()=>{
    setNotes(props.notes)
  }, [props.notes])

  const ctx = useContext(AppContext);

  const { handleBlur, setFieldValue, handleSubmit, values, errors, touched } = useFormik<INoteRequest>({
    initialValues: {
      companyId: props.companyId,
      jobId: props.jobId,
      applicationId: props.applicationId,
      text: "",
    },
    validationSchema: NoteRequestSchema,
    onSubmit: async (values) => {
      try {
        setReadOnly(true);
        console.log(values)
        const result = await ctx.api.applications.createNote(values);
        setNotes([...notes, result]);
        setFieldValue("text", "", false);
        ctx.setAlert("Note created", "success");
      } catch (e) {
        logger.error(e.message);
        ctx.setAlert(e.message, "error");
      } finally {
        setReadOnly(false);
      }
    },
  });

  /**
   * Returns error text if touched. Works with nested objects.
   */
  const getError = (name: string): string => {
    return getIn(touched, name) && getIn(errors, name)
      ? getIn(errors, name)
      : "";
  };

  const Note = (note: IApplicationNote) => {
    return <ListItem key={note.id}>
      <ListItemText primary={note.note} secondary={`${(new Date(note.createdAt)).toLocaleString()} ${note.userDisplayName}`} />
    </ListItem>
  }

  const formProps = {
    getError,
    getValue: (name: string) => getIn(values, name),
    handleBlur,
    setFieldValue,
    readOnly
  }

  return (
    <Grid item xs={12} md={6}>
      <Typography
        component="span"
        variant="h4"
        color="textPrimary"
      >
        Notes</Typography>
      <List>
        {notes.map(x => (<Note {...x} key={x.id} />))}
      </List>
      <TitanTextField
        name="text"
        label="Note"
        multiline={true}
        {...formProps}
      />
      <Button
        variant="contained"
        color="primary"
        className="submit-button"
        onClick={() => handleSubmit()}
        disabled={readOnly}
      >Add</Button>
    </Grid>
  );
}