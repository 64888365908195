import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import Slide from "@material-ui/core/Slide";
import { AppAlert } from "../../models/appAlert";
import { Alert } from "@material-ui/lab";

interface IProps {
  alert: AppAlert;
  clear: () => void;
}
export class AppSnackbar extends React.Component<IProps, {}> {
  render() {
    if (!this.props.alert || !this.props.alert.message) {
      return null;
    }
    return (
      <Snackbar
        open={this.props.alert.message ? true : false}
        onClose={() => this.props.clear()}
        TransitionComponent={Slide}
      >
        <Alert
          onClose={() => this.props.clear()}
          severity={this.props.alert.type}
        >
          {this.props.alert.message}
        </Alert>
      </Snackbar>
    );
  }
}
