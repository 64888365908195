import {Box, Button, Grid, Typography} from '@material-ui/core';
import {getIn, useFormik} from 'formik';
import React, {useContext, useEffect, useState} from 'react';
import {IApplicantProfile} from '../../shared/apiModels';
import AppContext from '../../contexts/appContext';
import {defaultApplicantProfile} from '../../hooks/useApplicant';
import {getLogger} from '../../services/loggingService';
import {
  TitanDateField,
  TitanPositionField,
  TitanSelectField,
  TitanSkillField,
  TitanTextField,
  TitanToolTip
} from '../base/titanFormInput';
import {TitanLoading} from '../base/titanLoading';
import {useJobPublicInfo} from '../../hooks/useJobPublicInfo';
import {ApplicantProfileSchema} from '../../shared/apiValidation';
import {FormOptions} from '../data/formOptions';
import {useParams} from 'react-router-dom';
import {DeiInformation} from './deiInformation';

const logger = getLogger('ApplicantProfileCreate');

const field: any = {
  xs: 12,
  sm: 6
};

interface IParams {
  siteUrl: string;
  id: string;
}

export function ApplicantProfileCreate() {
  const ctx = useContext(AppContext);

  const {siteUrl, id} = useParams() as IParams;

  const [readOnly, setReadOnly] = useState(false);
  const job = useJobPublicInfo(siteUrl, id);

  const {handleBlur, setFieldValue, handleSubmit, values, errors, touched} = useFormik<IApplicantProfile>({
    initialValues: defaultApplicantProfile(siteUrl, '', id),
    validationSchema: ApplicantProfileSchema,
    onSubmit: async (values) => {
      logger.debug('submit', {values});
      try {
        setReadOnly(true);
        await ctx.api.apply.postProfile(values);
        ctx.setAlert('Applicant Profile created', 'success');
      } catch (e) {
        logger.error(e.message);
        ctx.setAlert(e.message, 'error');
        setReadOnly(false);
      }
    }
  });

  useEffect(() => {
    if (job.result) {
      setFieldValue('companyId', job.result.companyId);
      setFieldValue('jobTitle', job.result.title);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (window as {[key: string]: any})['showCalculator']();
      //THIS WILL CHANGE THE COLOR IN THE CALCULATOR
      setTimeout((_) => {
        (window as {[key: string]: any})['changeCalculatorColor']();
      }, 2200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job.result]);

  /**
   * Returns error text if touched. Works with nested objects.
   */
  const getError = (name: string): string => {
    return getIn(touched, name) && getIn(errors, name) ? getIn(errors, name) : '';
  };

  if (job.loading) {
    return <TitanLoading />;
  }

  if (job.result && !job.result.visible) {
    return (
      <Grid item xs={3} alignContent={'center'}>
        <Typography variant={'h3'}>Oops!</Typography>
        <Typography variant={'subtitle1'}>This job is not active</Typography>
      </Grid>
    );
  }

  const formProps = {
    getError,
    getValue: (name: string) => getIn(values, name),
    handleBlur,
    setFieldValue,
    readOnly
  };

  return (
    <Grid item container xs={12} md={8} spacing={3} className="titan-form">
      <Grid item xs={12}>
        <Typography variant="h2" component="h2" gutterBottom>
          {job.result.title}
          <span style={{float: 'right'}}>
            <DeiInformation siteUrl={siteUrl} />
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography component="h3" variant={'subtitle1'}>
          <span dangerouslySetInnerHTML={{__html: job.result.description}} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Candidate Profile
        </Typography>
      </Grid>

      <TitanTextField
        name="headline"
        label="Your Profile Headline"
        helperText='Create a "News Headline" that conveys your experience and talent level. Example: "IT Recruiting Guru"'
        {...formProps}
        {...field}
      />
      <TitanSelectField
        name="education"
        label="Do you have the required education for this role?"
        options={FormOptions.YesNo}
        {...formProps}
        {...field}
      />

      <TitanTextField
        name="problemsSolved"
        label="What Problem(s) Do You Solve?"
        helperText='What are the main projects or types of work at which you are an expert?'
        multiline={true}
        xs={12}
        {...formProps}
      />
      {/* Skills Area */}
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" gutterBottom>
          Skills{' '}
          {job.result.skills.length > 0 && (
            <TitanToolTip
              text="Share your skills, below are drop down options for the skills prioritized for this role. Please rate your current talent level for each skill."
              placement={'right'}
            />
          )}
        </Typography>
        {job.result.skills.length > 0 && (
          <small>Please click the text box, choose a skill, and then select your proficiency in that skill.</small>
        )}
      </Grid>
      <TitanSkillField name="skill1" label="Skill 1" {...formProps} options={job.result.skills} />
      <TitanSkillField name="skill2" label="Skill 2" {...formProps} options={job.result.skills} />
      <TitanSkillField name="skill3" label="Skill 3" {...formProps} options={job.result.skills} />
      <TitanSkillField name="skill4" label="Skill 4" {...formProps} options={job.result.skills} />
      <TitanTextField name="additionalSkills" label="Additional Skills" multiline={true} {...formProps} xs={12} />

      <Grid item xs={12}>
        {/* I am a spacer */}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Experience
        </Typography>
        <Typography variant={'subtitle1'}>
          Please share with us your accomplishments, experience, and skills used in previous roles that will relate to
          this position
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {/* I am a spacer */}
      </Grid>
      <Box marginLeft={2} marginRight={1}>
        <Grid container spacing={6}>
          <TitanPositionField name="position1" {...formProps} />
          <TitanPositionField name="position2" {...formProps} />
          <TitanPositionField name="position3" {...formProps} />
        </Grid>
      </Box>

      <Grid item xs={12}>
        {/* I am a spacer */}
      </Grid>
      <Grid item xs={12}>
        {/* I am a spacer */}
      </Grid>
      <Grid item xs={12}>
        {/* I am a spacer */}
      </Grid>
      <TitanTextField
        name="volunteer"
        label="Volunteer Experience"
        helperText="We want to know about the causes that matter to you, and the experiences you have gained in your efforts with them. Please feel free to leave off organization names that disclose race, gender, religion, etc... and just describe your work within those organizations if you prefer."
        multiline={true}
        {...formProps}
        xs={12}
        lg={4}
      />

      <TitanTextField
        name="averageTenure"
        label="Average Job Tenure"
        helperText="Please use the calculator below to calculate your average time in your previous roles."
        multiline={true}
        {...formProps}
        xs={12}
        lg={4}
      />

      <TitanDateField name="startAvailability" label="Start Availability" {...formProps} xs={12} lg={4} />

      {/* Cover Letter */}
      <TitanTextField
        name="coverLetter"
        label="In two sentences, please tell us what is unique about you as a candidate"
        multiline={true}
        {...formProps}
        xs={12}
      />
      <Grid item md={4}></Grid>
      <Grid item md={4} xs={12} alignContent={'center'}>
        <div className="calconic-calculator" data-calculatorid="6215390e4052430021dcd67f"></div>
      </Grid>

      {/* Contact Info */}
      <Grid item xs={12}>
        <Typography variant="h3" component="h3" gutterBottom>
          Contact Info
        </Typography>
        <Typography variant={'subtitle1'}>
        Your contact information, including name, will be hidden from the hiring team until they invite you to move forward, at which time you will receive a request to unlock your information.
        </Typography>
      </Grid>
      <TitanTextField name="firstName" label="First Name" {...formProps} {...field} />
      <TitanTextField name="lastName" label="Last Name" {...formProps} {...field} />
      <TitanTextField name="email" label="Email" {...formProps} {...field} />
      <TitanTextField name="phone" label="Phone" {...formProps} {...field} />

      <Grid item xs={12}>
        <Button
          variant="contained"
          color="primary"
          className="submit-button"
          onClick={() => handleSubmit()}
          disabled={readOnly}
        >
          Submit
        </Button>
      </Grid>
    </Grid>
  );
}
