import { Button, Grid, List, ListItem, ListItemIcon, ListItemText, MenuItem, Typography, Select, FormControl, InputLabel } from "@material-ui/core";
import { getIn, useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { IApplicationMessage, IAppId, IMessageRequest, PreloadedMessages } from "../../shared/apiModels";
import AppContext from "../../contexts/appContext";
import { TitanTextField } from "../base/titanFormInput";
import { NoteRequestSchema } from "../../shared/apiValidation";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import PersonIcon from '@material-ui/icons/Person';
import { useCompanySettings } from "../../hooks/useCompanySettings";
interface IProps extends IAppId {
  messages: IApplicationMessage[]
}
export function Messages(props: IProps) {
  const [preloadedMessages, setPreLoadedMessages] = useState<PreloadedMessages[]>([]);
  const [readOnly, setReadOnly] = useState(false);
  const [messages, setMessages] = useState<IApplicationMessage[]>([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const ctx = useContext(AppContext);
  useEffect(() => {
    try{
      setMessages(props.messages.reverse() || []);
    } catch(error) {
      setMessages([]);
    }
  }, [props.messages])

  const [refresh] = useState(new Date());
  const settings = useCompanySettings(refresh);
  useEffect(() => {
    if (settings.result) {
      setPreLoadedMessages(settings.result.preloadedMessages);
    }
  }, [setPreLoadedMessages, settings.result]);

  

  
  const { handleBlur, setFieldValue, handleSubmit, values, errors, touched } = useFormik<IMessageRequest>({
    initialValues: {
      companyId: props.companyId,
      jobId: props.jobId,
      applicationId: props.applicationId,
      text: "",
    },
    validationSchema: NoteRequestSchema,
    onSubmit: async (values) => {
      try {
        setReadOnly(true);
        console.log(values)
        const result = await ctx.api.applications.sendMessage(values);
        setMessages([...messages as any, result]);
        setFieldValue("text", "", false);
        ctx.setAlert("Message sent", "success");
      } catch (e) {
        ctx.handleError(e);
      } finally {
        setReadOnly(false);
      }
    },
  });

  /**
   * Returns error text if touched. Works with nested objects.
   */
  const getError = (name: string): string => {
    return getIn(touched, name) && getIn(errors, name)
      ? getIn(errors, name)
      : "";
  };

  const renderMessage = (message: IApplicationMessage, index: number) => {
    const isCandidate = message.createdBy.toLowerCase() === "candidate";
    return <ListItem key={index}>
      <ListItemIcon>
        {isCandidate ? <PersonIcon /> : <AssignmentIndIcon color={"primary"} />}
      </ListItemIcon>
      <ListItemText secondary={`${new Date(message.createdAt).toLocaleString()} ${message.createdBy}`}>
        <span dangerouslySetInnerHTML={{ __html: message.body.replace("\n", "<br />") }} />
      </ListItemText>
    </ListItem>
  }

  const formProps = {
    getError,
    getValue: (name: string) => getIn(values, name),
    handleBlur,
    setFieldValue,
    readOnly
  }

  const handleMessageChange = (e: any) => {
    setFieldValue("text", e.target.value, false);
  }

  return (
    <Grid item xs={12} md={6}>
      <Typography
        component="span"
        variant="h4"
        color="textPrimary"
      >
        Messages</Typography>
        
      <List>
        {messages.map((x, i) => (renderMessage(x, i)))}
      </List>
      <FormControl variant="outlined" fullWidth={true} id="preloaded-messages-form">
        <InputLabel id={"preloaded-label"}>Custom Messages</InputLabel>
      <Select 
        displayEmpty
        onChange={handleMessageChange}
        
        defaultValue={""}
        label="Message"
        MenuProps={MenuProps}
        autoWidth
        fullWidth={true}
      >
        <MenuItem value="" key="">
        </MenuItem>
        {preloadedMessages?.map((msg) => (
          <MenuItem
            value={msg.body || ''}
            key={msg.name}
          >
            {msg.name || ''}
          </MenuItem>
        ))}
      </Select>
      </FormControl>
      <TitanTextField
        name="text"
        label="Message"
        multiline={true}
        {...formProps}
      />
      <Button
        variant="contained"
        color="primary"
        className="submit-button"
        onClick={() => handleSubmit()}
        disabled={readOnly}
      >Send</Button>
    </Grid>
  );
}