import {Box, Button, Grid, Link} from '@material-ui/core';
import React, {useContext, useState} from 'react';
import {useJobsList} from '../../hooks/useJobsList';
import AppContext from '../../contexts/appContext';
import {TitanLoading} from '../base/titanLoading';
import {IJobDetails} from '../../shared/apiModels';
import {useHistory} from 'react-router-dom';
import MaterialTable from 'material-table';

function Jobs() {
  const ctx = useContext(AppContext);
  const history = useHistory();
  const [refresh] = useState(new Date().getTime());

  const jobs = useJobsList(refresh);

  const renderLink = (id: string) => {
    const link = `${window.location.origin}/apply/${ctx.claims.site_url}/${id}`;
    return (
      <div>
        <Link href={link} target="_blank" rel="noreferrer" title="Apply for this job">
          {link}
        </Link>
      </div>
    );
  };

  const renderEditJobLink = (job: IJobDetails) => {
    //const link = `https://ib-dev.titan-ats.com/admin/jobs/${job.id}/edit`;
    const link = `/sso/admin/jobs/${job.id}/edit`;
    return (
      <div>
        <Link href={link} rel="noreferrer" title="Edit this job">
           {job.title}
        </Link>
      </div>
    )
  }

  const renderViewApplicantsLink = (job: IJobDetails) => {
    const link = `/${job.id}`;
    return (
      <div>
        <Link href={link} rel="noreferrer" title="View applicants">
           Applicants
        </Link>
      </div>
    )
  }

  if (jobs.loading) {
    return <TitanLoading />;
  }

  return (
    <Grid container>
      <Grid container justify={'flex-end'}>
        <Box marginBottom={3}>
          <Button variant={'contained'} color="primary" onClick={() => history.push('/sso/admin/jobs/new')}>
            Create Job
          </Button>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MaterialTable
          title=""
          columns={[
            {title: 'Title', field: 'title', render: (row: IJobDetails) => renderEditJobLink(row)},
            {title: 'Description', field: 'abstract'},
            {title: 'Apply Link', field: 'id', render: (row: IJobDetails) => renderLink(row.id)},
            {title: 'Apps', field: 'total_apps'},
            {
              title: 'Posted',
              field: 'posted_at',
              render: (row: IJobDetails) => new Date(row.posted_at).toLocaleString()
            }, 
            {title: 'View Applicants', render: (row: IJobDetails) => renderViewApplicantsLink(row)}
          ]}
          data={jobs.result}
          options={{
            pageSize: 10
          }}
        ></MaterialTable>
      </Grid>
    </Grid>
  );
}

export default Jobs;
