import {useContext, useEffect, useState} from 'react';
import {IJobPublicInfo} from '../shared/apiModels';
import AppContext from '../contexts/appContext';
import {getLogger} from '../services/loggingService';

export const useJobPublicInfo = (siteUrl?: string, jobId?: string) => {
  const context = useContext(AppContext);
  const logger = getLogger('useJobPublicInfo');

  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState(null as IJobPublicInfo);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        logger.debug('Fetching', {siteUrl, jobId});
        const data = await context.api.apply.getJobPublicInfo(siteUrl, jobId);
        setResult(data);
      } catch (error) {
        logger.error(error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    }

    if (siteUrl && jobId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteUrl, jobId]);

  return {
    error,
    loading,
    result
  };
};
