import {useContext, useEffect, useState} from 'react';
import AppContext from '../contexts/appContext';
import {getLogger} from '../services/loggingService';
import {CompanySettings} from '../shared/apiModels';

export const useCompanySettings = (refresh: Date) => {
  const context = useContext(AppContext);
  const logger = getLogger('useCompanySettings');

  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<CompanySettings>();

  async function fetchData() {
    context.setAlert('', 'success');
    try {
      setLoading(true);
      const data = await context.api.company.getSettings();
      setResult(data);
    } catch (error) {
      logger.error(error.message);
      context.setAlert(error.message, 'error');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return {
    loading,
    result
  };
};
