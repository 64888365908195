import { ICompanyApi } from "../../shared/apiInterfaces";
import { CompanySettings } from "../../shared/apiModels";
import { getLogger } from "../loggingService";
import { ApiClientBase } from "./apiClientBase";

export class CompanyApiClient extends ApiClientBase implements ICompanyApi {
  constructor(apiUrl: string) {
    super(apiUrl, getLogger("CompanyApiClient"), true);
  }

  async getSettings(): Promise<CompanySettings>{
    const result = await this.get(`/api/company/settings`);
    return await result.json();
  }
  async updateSettings(model: CompanySettings): Promise<void>{
    await this.post(`/api/company/settings`, model);
  };
}