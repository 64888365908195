import {Box, Button, Grid, IconButton, Typography} from '@material-ui/core';
import React, {useContext, useEffect, useState} from 'react';
import AppContext from '../../contexts/appContext';
import {useApplicant} from '../../hooks/useApplicant';
import {getLogger} from '../../services/loggingService';
import {TitanReadOnlyPositionField, TitanReadOnlySkill, TitanReadOnlyText} from '../base/titanFormInput';
import {TitanLoading} from '../base/titanLoading';
import queryString from 'query-string';
import {useJobPublicInfo} from '../../hooks/useJobPublicInfo';
import moment from 'moment';
import {Notes} from './notes';
import {Messages} from './messages';
import {Disposition} from './disposition';
import {ProfileIcons} from './profileIcons';


const logger = getLogger('ApplicantProfile');

export function ApplicantProfile() {
  const ctx = useContext(AppContext);

  const readOnly = true;

  const [loading, setLoading] = useState(true);
  const [jobId, setJobId] = useState('');
  const [applicationId, setApplicationId] = useState('');

  const job = useJobPublicInfo(ctx.claims.site_url, jobId);
  const applicant = useApplicant(jobId, applicationId);

  useEffect(() => {
    const parsed = queryString.parse(window.location.search) as {jobId: string; applicationId: string};
    if (!parsed.jobId) {
      logger.error('Page expects jobId parameter');
      ctx.setAlert('The application was not found, check your address.', 'error');
    }
    if (!parsed.applicationId) {
      logger.error('Page expects applicationId parameter');
      ctx.setAlert('The application was not found, check your address.', 'error');
    }
    setJobId(parsed.jobId);
    setApplicationId(parsed.applicationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (applicant.error) {
      ctx.setAlert(applicant.error, 'error');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicant.result, applicant.error]);

  useEffect(() => {
    setLoading(job.loading || applicant.loading);
  }, [job.loading, applicant.loading]);

  if (loading) {
    return <TitanLoading />;
  }

  if (!applicant.result) {
    return null;
  }

  return (
    <Grid item container xs={12} md={7} spacing={3} className="titan-form">
      <Grid item xs={12}>
        <Typography variant="h2" component="h2" gutterBottom>
          {job.result.title}
        </Typography>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant={'subtitle1'}>
          <span>{job.result.abstract}</span>
        </Typography>
      </Grid>

      <Disposition
        appId={applicant.id}
        status={applicant.result.status}
        rejectionSent={applicant.result.rejectMessageSent}
      />

      <ProfileIcons appId={applicant.id} lockStatus={applicant.result.lockStatus} applicant={applicant.result} />
      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          {applicant.result.lockStatus === 'unlocked'
            ? `${applicant.result.firstName} ${applicant.result.lastName}`
            : 'Candidate Profile'}
        </Typography>
        {applicant.result.lockStatus === 'unlocked' && (
          <Typography variant={'h5'}>
            <a href={`mailto:${applicant.result.email}`}>{applicant.result.email}</a> {applicant.result.phone}
          </Typography>
        )}
      </Grid>

      <TitanReadOnlyText name="headline" label="Headline" value={applicant.result.headline} xs={12} sm={6} />
      <TitanReadOnlyText
        name="education"
        label="Education"
        multiline={true}
        value={applicant.result.education}
        xs={12}
        sm={6}
      />

      <TitanReadOnlyText
        name="problemsSolved"
        label="What Problem(s) Do You Solve?"
        multiline={true}
        value={applicant.result.problemsSolved}
        xs={12}
      />

{/* Skills Area */}
<Grid item xs={12}>
  <Typography variant="h4" gutterBottom>
    Skills
  </Typography>
</Grid>
<TitanReadOnlySkill name="skill1" label="Skill 1" value={applicant.result.skill1} />
<TitanReadOnlySkill name="skill2" label="Skill 2" value={applicant.result.skill2} />
<TitanReadOnlySkill name="skill3" label="Skill 3" value={applicant.result.skill3} />
<TitanReadOnlySkill name="skill4" label="Skill 4" value={applicant.result.skill4} />
<TitanReadOnlyText
  name="additionalSkills"
  label="Additional Skills"
  multiline={true}
  value={applicant.result.additionalSkills}
  xs={12}
/>

<Grid item xs={12}>
        {/* I am a spacer */}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3" gutterBottom>
          Experience
        </Typography>
      </Grid>

      <TitanReadOnlyPositionField name="position1" value={applicant.result.position1} />

      <TitanReadOnlyPositionField name="position2" value={applicant.result.position2} />

      <TitanReadOnlyPositionField name="position3" value={applicant.result.position3} />

      <TitanReadOnlyText
        name="volunteer"
        label="Volunteer Experience"
        multiline={true}
        value={applicant.result.volunteer}
        xs={12}
        lg={4}
      />

      <TitanReadOnlyText
        name="averageTenure"
        label="Average Tenure"
        multiline={true}
        value={applicant.result.averageTenure}
        xs={12}
        lg={4}
      />

      <TitanReadOnlyText
        name="startAvailability"
        label="Start Availability"
        value={applicant.result.startAvailability ? moment(applicant.result.startAvailability).format('M/D/YYYY') : ''}
        xs={12}
        lg={4}
      />


      {/* Cover Letter */}
      <TitanReadOnlyText
        name="coverLetter"
        label="In two sentences, please tell us what is unique about you as a candidate"
        multiline={true}
        value={applicant.result.coverLetter}
        xs={12}
      />

      <Notes {...applicant.id} notes={applicant.result.notes} />
      <Messages {...applicant.id} messages={applicant.result.messages} />

      {!readOnly && (
        <Grid item xs={12}>
          <Button variant="contained" color="primary" className="submit-button">
            Submit
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
