import { IRegisterApi } from "../../shared/apiInterfaces";
import { IRegister } from "../../shared/apiModels";
import { getLogger, Logger } from "../loggingService";
import { ApiClientBase } from "./apiClientBase";


export class RegisterApiClient extends ApiClientBase implements IRegisterApi {
  logger: Logger;

  constructor(apiUrl: string) {
    super(apiUrl, getLogger("RegisterApi"), false);
  }

  async register(model: IRegister): Promise<{message: string}> {
    const response = await this.post("/api/register", model);
    return await response.json();
  }
}
