export class Logger {
  private logTrace: boolean = true;

  constructor(private name: string) {}

  debug(...properties: any[]) {
    if (this.logTrace) {
      console.debug(this.name, ...properties);
    }
  }

  error(...properties: any[]) {
    console.error(this.name, ...properties);
  }
}

const loggers: {[name: string]: Logger} = {};

export const getLogger = (serviceName: string): Logger => {
  if (!loggers[serviceName]) {
    loggers[serviceName] = new Logger(serviceName);
  }
  return loggers[serviceName];
};
