import { IUserApi } from "../../shared/apiInterfaces";
import { IUser } from "../../shared/apiModels";
import { getLogger } from "../loggingService";
import { ApiClientBase } from "./apiClientBase";

export class UserApi extends ApiClientBase implements IUserApi {

  constructor(apiUrl: string){
    super(apiUrl, getLogger("UserApiClient"), true)
  }

  async getUsers(): Promise<IUser[]> {
    const result = await this.get("/api/users");
    return await result.json();
  }

  async createUser(user: IUser): Promise<IUser> {
    const result = await this.post("/api/users", user);
    return await result.json();
  }
  async updateUser(user: IUser): Promise<void> {
    await this.put("/api/users", user);
  }
  async deleteUser(userId: string): Promise<void> {
    await this.delete(`/api/users?id=${userId}`);
  }
}