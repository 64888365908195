import {useContext, useEffect, useState} from 'react';
import {IJobDetails} from '../shared/apiModels';
import AppContext from '../contexts/appContext';
import {getLogger} from '../services/loggingService';

const logger = getLogger('useJobsList');

/**
 *
 * @param timestamp - used to trigger a refresh, can also be used to de-bounce
 */
export const useJobsList = (timestamp: number) => {
  const context = useContext(AppContext);

  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState([] as IJobDetails[]);
  const [error, setError] = useState('');

  useEffect(() => {
    async function fetchData() {
      setError('');
      setLoading(true);
      try {
        logger.debug('Fetching', {timestamp});
        const data = await context.api.jobs.getJobs();
        setResult(data);
      } catch (error) {
        logger.error(error.message);
        setError(error.message || 'An unknown error has occurred');
        setResult([]);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  return {
    error,
    loading,
    result
  };
};
