import React, { useContext, useEffect, useState } from "react"
import AppContext from "../../contexts/appContext";
import { TitanLoading } from "../base/titanLoading";

interface IProps {

}
export function SsoContainer(props: IProps){

  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState("");

  const ctx = useContext(AppContext);

  useEffect(() => {

    const getUrl = async () => {
      const path = window.location.toString().replace(`${window.location.protocol}//${window.location.host}/sso`, "");

      if (!path) {
        // Must be signed in with SSO redirect,
        const response = await ctx.api.misc.getSso();
        return response.ssoUrl;
      }

      const basePath = `https://${ctx.claims.site_url}.titan-ats.com`;
      return basePath + path;
    }

    getUrl()
      .then(url => setUrl(url))
      .catch(error => {
        ctx.setAlert(error.message, "error");
      }).finally(() => {
        setLoading(false);
      })
  }, [ctx])

  if (loading) {
    return <TitanLoading />;
  }

  if (!url) {
    return null;
  }

  return (<iframe title="ATS" width={"100%"} src={url} />)
}