import {
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";

export default class Logout extends React.Component {
  render() {
    return (
      <Grid item xs={6} className="logout" alignContent={"center"}>
        <Typography variant="h4" component="p" align={"center"} color={"primary"}>
          Logout Succeeded
        </Typography>
      </Grid>
    );
  }
}